import Shimmer from 'Layout/Shimmer';
import classes from '../../ReportTable/MobileComplianceTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import dayjs from 'dayjs'
// import { modalActions } from 'Store/modal-redux';
// import Action from './Action';
import PaymentStatusTag from '../../../Components/ReportTable/PaymentStatusTag';
import {Pagination, Tooltip} from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import ReactDOM from 'react-dom'
import { useState } from 'react';

const MobileComplianceChecksTable = (props) => {

    console.log('props.tableData',props.tableData)
    function abbreviateString(str) {
        if (str.length > 20) {
            return str.substr(0, 15) + '...';
        }
        return str;
    }

    if (props.tableData?.length > 0)
        return (
            <>
                <ReportTableLayout className={`${classes.table} ${props.className}`}>
                    {props?.tableData?.map((data, index) => {
                        console.log('data',data)
                        return (
                            <div key={`${data?.email}-${index}`} className={classes.row}>
                                <div
                                    className={classes.infoTwo}
                                >
                                    <div className={clsx(classes.amountText)} style={{
                                        maxWidth: "100%",
                                        overflowWrap: "anywhere",
                                        color: "#1e333fc7",
                                        fontWeight: "400"
                                    }}>
                                        {data?.searchTerm ? data?.searchTerm : '--'}
                                    </div>
                                </div>
                                <div className={classes.infoOne}>
                                    <div
                                        className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                                    >
                                        <div
                                            className={clsx(classes.amountText, '!text-[#1E333F50]')}
                                        >
                                            Search Id
                                        </div>
                                    </div>
                                    <div className={clsx(classes.amountText, 'flex gap-[5px]')}
                                         style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                        {data?.searchId ? data?.searchId : '--'}
                                        {/* {data?.searchDate ? dayjs(data?.searchDate).local().format('hh:mm A')  : '--'} */}
                                    </div>
                                </div>

                                <div className={classes.infoOne}>
                                    <div
                                        className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                                    >
                                        <div
                                            className={clsx(classes.amountText, '!text-[#1E333F50]')}
                                        >
                                            Search Date
                                        </div>
                                    </div>
                                    <div className={clsx(classes.amountText, 'flex gap-[5px]')}
                                         style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                        {data?.searchDate ? dayjs(data?.searchDate).local().format('DD MMM YYYY') : '--'}
                                        {/* {data?.searchDate ? dayjs(data?.searchDate).local().format('hh:mm A')  : '--'} */}
                                    </div>
                                </div>
                                <div
                                    className={classes.infoTwo}
                                >
                                    <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>References Found</div>
                                    <div className={clsx(classes.amountText)}
                                         style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                        {data?.totalHits ? data?.totalHits : "--"}
                                    </div>
                                </div>

                                <div
                                    className={classes.infoTwo}
                                >
                                    <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Status</div>
                                    <div className={clsx(classes.amountText)}
                                         style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                        {data?.matchStatus ?
                                            <PaymentStatusTag status={data?.matchStatus} type="light"/> : "--"}
                                    </div>
                                </div>
                                <div className={classes.infoTwo}>
                                    <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Risk Level</div>
                                    <div className={clsx(classes.amountText)}
                                         style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                        {data?.riskLevel ? data?.riskLevel : "--"}
                                    </div>
                                </div>

                                <div className={classes.infoTwo}>
                                    <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Action</div>
                                    <div className="flex flex-col gap-[4px]">
                                        <button
                                            className="p-[4px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                            onClick={() => props.handleCustomerSelection(data)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </ReportTableLayout>
            </>
        )
    if (props.tableData?.length === 0)
        return (
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '4rem',
                    fontSize: '2.5rem'
                }}
                className={classes.table}
            >
                No compliance search found.
            </div>
        )
}

export default MobileComplianceChecksTable