import Backdrop from 'Layout/Backdrop'
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import Share from 'assets/Icons/share-white.svg'
import classes from './InvoiceDetailsModal.module.scss'
import leftPointer from 'assets/Icons/arrow-left.svg'
import { useSnackbar } from 'notistack'
import InvoiceDetailsModalHeader from './InvoiceDetailsModalHeader'
import InvoiceDetailsPaymentRail from './InvoiceDetailsPaymentRail'
import shareIcon from 'assets/Icons/share-2.svg'
import copyLink from 'assets/Images/copy-link.svg'
import { Skeleton, Tabs } from 'antd'
import InvoiceDetailsTabContent from './InvoiceDetailsTabContent'
import UseApiCall from 'hooks/useApiCall'
import { apiSendPaymentLink, apiDownloadFiraReportUrl } from 'services'
import ButtonSet from 'Components/atom/ButtonSet/ButtonSet'
import { useEffect, useRef, useState } from 'react'
import BankAccountDetailsTabContent from './BankAccountDetailsTabContent'
import PaymentStatusTabContent from './PaymentStatusTabContent'
import Close from 'Layout/Close'
import { useNavigate } from 'react-router'
import UnderVerification from '../PaymentStatus/UnderVerification'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import TypeOtp from 'Components/otpInnerScreen/TypeOtp'
import EditInvoice from 'Components/Invoice/EditInvoice'
import {
  fetchFileFromS3,
  apiGetNewPaymentDetails,
  apiGetInvoiceLinkOTP,
  apiGetPaymentDetails,
  apiGetInvoicesPresignedUrlsWithOtp,
 } from 'services'
import RightIcon from 'assets/Icons/chevron-right-white.svg'
import quoteIcon from 'assets/Icons/message-square.svg'
// import EnterOtp from 'Components/otpScreen/EnterOtp'
// import TypeOtp from 'Components/otpScreen/TypeOtp'
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';

const copyTextToClipboard = (text) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text)
  }
  return document.execCommand('copy', true, text)
}

const InvoiceDetailsModal = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { activeTab } = props
  const navigate = useNavigate()
  const [defaultActiveKey] = useState(activeTab)
  const invoice = useSelector((state) => state.receivable.selected_invoice)
  const isCustomerDetailsModalOpen = useSelector((state) => state.modal.showCustomerDisplay);
  const [showFiraButton, setShowFiraButton] = useState(false)
  const [currencySign, setCurrencySign] = useState('$')
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)

  // let invoiceData = invoice?.invoice_details_summary?.presigned_urls 
  const [invoicesData, setInvoicesData] = useState(invoice?.invoice_details_summary?.presigned_urls || [])
  const [invoices, setInvoices] = useState([])
  const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(invoice?.is_otp_required || false);  // for now i use a state , once new API deployed i will fetch it from backend response
  const [otpViewNowBox,setOtpViewNowBox] = useState(false);
  const transId = useRef(0);
  const transIdpaymentLinkOtpRequired = useRef(false);
  const [otpData, setOtpData] = useState(null)
  const [errorState, setErrorState] = useState({ error: false })
  const [otpErrorState, setOtpErrorState] = useState({ error: false })
  const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
  const [otp, setOtp] = useState(['', '', '', '','','']); 
  const [otpSuccess,setOtpSuccess] = useState(true);
  const [lockInvoices, setLockInvoices] = useState([])
  const [unlockInvoices, setunLockInvoices] = useState([])
  const [invoicetoBigScreen, setInvoicetoBigScreen] = useState([])

  const [invoicesLocked, setInvoicesLocked] = useState([
    {
      type : "image/png",
      url : RightIcon
    }
  ])

  const handleOTPView = () => {
    setOtpViewNowBox((prev) => !prev);
    setPresignedUrlErrorState({
      error: false
    })
    // setPaymentLinkOtpRequired((prev) => !prev);

    const params = {invoiceId: invoice?.invoice_id}

    // const currentTime = new Date().getTime();
    // const expirationTime = parseInt(localStorage.getItem(`timerExpiration${invoice?.invoice_id}`), 10) || 0;
  
    // if (expirationTime - currentTime <= 0) {
    //   fetchOtpData(params);
    // }
    fetchOtpData(params)
  }

  const handleResendOTPView = () => {
    const params = {invoiceId: invoice?.invoice_id}
    fetchOtpData(params)
  }


  const handleOTPViewNow = () => {
    // setOtpViewNowBox(false);
    // setPaymentLinkOtpRequired(false);
    fetchPresignedUrl({ invoiceId: invoice?.invoice_id, otp: otp.join('')})
  }


  // const [fetchInvoiceData, fetchingInvoiceLoader] = UseApiCall(
  //   apiGetNewPaymentDetails,
  //   (res) => {
  //       setPaymentLinkOtpRequired(res?.data?.paymentLinkOtpRequired)
  //       // console.log("transactionId",res?.data.transactionId)
  //       transId.current = res?.data?.transactionId
  //       transIdpaymentLinkOtpRequired.current = res?.data?.paymentLinkOtpRequired
  //       // !paymentLinkOtpRequired && fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  //       if(!transIdpaymentLinkOtpRequired.current){
  //         fetchPresignedUrl({ transactionId: transId.current , otp : otp.join('')})
  //       }
  //   },
  //   (err) => {
  //     setErrorState({
  //       error: true,
  //       title: JSON.stringify(err?.data?.error_response?.message) || '',
  //       subtext: 'Please generate a new link.',
  //     })
  //   }
  // )
  

  const [fetchOtpData] = UseApiCall(
    apiGetInvoiceLinkOTP,
    async (res) => {
      try {
        // setOtpViewNowBox((prev) => !prev);
        setOtpErrorState({
          error: false
        })
        setPresignedUrlErrorState({
          error: false
        })
        const decryptValue = doEncryption ? handleDecrypt(res) : res;
        setOtpData(decryptValue?.data);
      } catch (error) {
        console.error('Data processing error:', error);
      }
    },
    async (err) => {
      try {
        // Assuming err.data is an object
        console.error(err?.data?.error_response?.message, "presss");
        setOtpErrorState({
          error: true,
          title: err?.data?.error_response?.message || '',
          subtext: 'Please check your emailId.',
        });
        setPresignedUrlErrorState({
          error: false
        })
      } catch (error) {
        // Handle any errors that occur during error handling
        console.error('Error handling error:', error);
      }
    }
  );
  


  const [fetchPresignedUrl, fetchingPresignedUrl, fetchInvoiceDataOtp] = UseApiCall(
    apiGetInvoicesPresignedUrlsWithOtp,
    (res) => {
      setOtpViewNowBox(false);
      setPaymentLinkOtpRequired(false);
      setPresignedUrlErrorState({
        error: false
      })
      localStorage.removeItem(`timerStart${invoice?.invoice_id}`);
      localStorage.removeItem(`timerExpiration${invoice?.invoice_id}`);
      setOtpErrorState({
        error: false
      })
      setInvoices([])
      setOtpSuccess(false)
      // invoiceData = res?.data;
      let decryptValue = res;;
      // if (doEncryption) {
      //   decryptValue = handleDecrypt(res);
      // }
      // const decryptValue = doEncryption ? handleDecrypt(res) : res;
      setInvoicesData(decryptValue?.data)
      decryptValue?.data?.map(
        (item) => {
          // console.log(item.url)
          getFilesFromS3(item.url)
        }
      )
    },
    (err) => {
      // console.log(err)
      enqueueSnackbar(`${err?.data?.error_response?.message}`, { variant: 'error' })
      setOtpViewNowBox(true)
      setOtpErrorState({
        error: false
      })
      setPresignedUrlErrorState({
        error: true,
        title: err?.data?.error_response?.message || '',
        subtext: 'Not able to fetch Invoices',
      })
    }
  )

  const invoiceLockData = invoicesData?.filter((item)=> item.isLocked === true) || invoicesLocked;
  const invoiceunLockData = invoicesData?.filter((item)=> item.isLocked === false) || invoicesLocked;

  const [getLockFilesFromS3, fetchingLockFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        // const decryptValue = doEncryption ? handleDecrypt(res) : res;
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setLockInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
            isLocked: true,
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  const [getunLockFilesFromS3, fetchingunLockFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        // const decryptValue = doEncryption ? handleDecrypt(res) : res;
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setunLockInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
            isLocked: false,
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  const downloadLockInvoices = () => {
    invoiceLockData?.map((item, index) => {
      getLockFilesFromS3(item?.url)
      return item
    })
  }

  const downloadunLockInvoices = () => {
    invoiceunLockData?.map((item, index) => {
      getunLockFilesFromS3(item?.url)
      return item
    })
  }

  useEffect(()=>{
    const concatenateInvoices = unlockInvoices.concat(lockInvoices)
    // console.log('concatenateInvoices',concatenateInvoices)
    setInvoices(concatenateInvoices)
    setInvoicetoBigScreen([concatenateInvoices[0]])
    // localStorage.setItem('unlockInvoice', JSON.stringify(unlockInvoices));
    // localStorage.setItem('lockInvoice', JSON.stringify(lockInvoices));
  },[lockInvoices,unlockInvoices])

  useEffect(() => {
      downloadLockInvoices()
      downloadunLockInvoices()
  }, [invoicesData])


  // useEffect(() => {
  //   localStorage.setItem("lockInvoices",lockInvoices)
  //   localStorage.setItem("unlockInvoices",unlockInvoices)
  // }, [invoicesData])

  // console.log('fetchInvoiceDataOtp',fetchInvoiceDataOtp)
  // console.log('invoiceLockData',invoiceLockData)
  // console.log('invoiceunLockData',invoiceunLockData)
  // console.log('lockInvoice',lockInvoices)
  // console.log('unlockInvoice',unlockInvoices)
  // console.log('invoicetoBigScreen',invoicetoBigScreen)

  // const [getFilesFromS3, fetchingFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       setInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //         },
  //       ])
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // )

  const [getFilesFromS3, fetchingFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        // const decryptValue = doEncryption ? handleDecrypt(res) : res;
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  useEffect(() => {
    setCurrencySign(invoice?.invoice_details_summary?.invoice_attributes?.invoice_amount_currency_sign)
  }, [invoice])

  const statusIndicators = [
    {
      status: invoice.invoice_status,
    },
  ]
  if (invoice.is_invoice_overdue) {
    statusIndicators.push({
      status: 'overdue',
    })
  }

  useEffect(() => {
      if (invoice?.fira_status?.toLowerCase() === 'generated') setShowFiraButton(true);
  }, [invoice])

  const handleCopy = (paymentLink) => {
    try {
      copyTextToClipboard(paymentLink)
      enqueueSnackbar('Payment link copied!', { variant: 'success' })
    } catch (error) {}
  }
  const [sendPaymentLink] = UseApiCall(apiSendPaymentLink, () => {
    enqueueSnackbar('Payment link sent Successfully', { variant: 'success' })
  })
  const handleSendPaymentLink = () => {
    const { invoice_id, payment_link } = invoice
    sendPaymentLink({ invoiceId: invoice_id, paymentLink: encodeURIComponent(payment_link) })
  }

  // if payment_status matches
  const check = ['IN_PROGRESS', 'IN_REVIEW', 'SETTLED'].includes(
    props?.invoice?.invoice_status
  )
  let active = false
  let isVisible = false

  if (check) {
    active = false
    isVisible = true
  } else {
    active = true
    isVisible = true
  }

  const arrBtn = [
    {
      text: 'Send Link',
      icon: shareIcon,
      active: (statusIndicators[0]?.status === "CREATED" || statusIndicators[0]?.status === "PARTIALLY_PAID" || statusIndicators[0]?.status === "LINK_SENT" || (statusIndicators[0]?.status === "IN_PROGRESS" && Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount || 0) > 0)) ? true : false,
      isVisible,
      onClick: () => {
        if (statusIndicators[0]?.status === "CREATED" || statusIndicators[0]?.status === "PARTIALLY_PAID" || statusIndicators[0]?.status === "LINK_SENT" || (statusIndicators[0]?.status === "IN_PROGRESS" && Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount || 0) > 0)) {
          handleSendPaymentLink()
        }
      },
    },
    {
      text: 'Copy Link',
      icon: copyLink,
      active: (statusIndicators[0]?.status === "CREATED" || statusIndicators[0]?.status === "PARTIALLY_PAID" || statusIndicators[0]?.status === "LINK_SENT" || (statusIndicators[0]?.status === "IN_PROGRESS" && Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount || 0) > 0)) ? true : false,
      isVisible,
      onClick: () => {
        if (statusIndicators[0]?.status === "CREATED" || statusIndicators[0]?.status === "PARTIALLY_PAID" || statusIndicators[0]?.status === "LINK_SENT" || (statusIndicators[0]?.status === "IN_PROGRESS" && Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount || 0) > 0)) {
          handleCopy(invoice.payment_link)
        }
      },
    },
    {
      text: 'Payment Advice',
      icon: Share,
      active: showFiraButton ? true : false,
      isVisible,
      onClick: () => {
        handleReportDownload(invoice?.invoice_id)
      },
    },
  ]

  function downloadWithInterval(urls, intervalTime) {
    function download() {
      var url = urls.pop();
  
      if (url) {
        var a = document.createElement("a");
        a.setAttribute('href', url);
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank');
        a.click();
      }
  
      if (urls.length === 0) {
        clearInterval(interval);
      }
    }
  
    // Initial download
    download();
  
    // Set interval for subsequent downloads
    var interval = setInterval(download, intervalTime);
  }

  const [downloadFiraReport, getFileUrlFetching] = UseApiCall(
    apiDownloadFiraReportUrl,
    (res) => {
      const decryptValue = doEncryption ? handleDecrypt(res) : res;
      const fileUrls = decryptValue?.data;
      downloadWithInterval(fileUrls, 1000);
    }, (err) => {
      enqueueSnackbar("Error in downloading Fira Report.", { variant: 'error' })
    }
  )

  const handleReportDownload = async (invoiceId) => {
    await downloadFiraReport({ invoiceId })
  }

  const dispatch = useDispatch()
  const onTabChange = (key) => {
    if(isCustomerDetailsModalOpen) return;
    if (key === 'details') {
      window.history.pushState(
        {},
        undefined,
        `/receivables/details?invoiceId=${invoice.invoice_id}`
      )
    } else if (key === 'paymentStatus') {
      window.history.pushState(
        {},
        undefined,
        `/receivables/payment-status?invoiceId=${invoice.invoice_id}`
      )
    } else if (key === 'bankAccount') {
      window.history.pushState(
        {},
        undefined,
        `/receivables/bank-account?invoiceId=${invoice.invoice_id}`
      )
    }
    showSkeleton()
  }
  const [loading, setLoading] = useState(true)
  const showSkeleton = () => {
    // setLoading(true)
    // setTimeout(() => {
    setLoading(false)
    // }, 100)
  }
  useEffect(() => {
    showSkeleton()
  }, [])

  // console.log('openedInvoice',props?.openedInvoice)

  const items = [
    {
      key: 'details',
      label: `Details`,
      children: (
        <Skeleton loading={loading}>
          <InvoiceDetailsTabContent
            invoice={invoice}
            invoices={invoices}
            setOtpViewNowBox={setOtpViewNowBox}
            otpViewNowBox={otpViewNowBox}
            setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
            paymentLinkOtpRequired={paymentLinkOtpRequired}
            handleResendOTPView={handleResendOTPView}
            otpErrorState={otpErrorState}
            presignedUrlErrorState={presignedUrlErrorState}
            invoiceData={invoicesData}
            invoiceLockData={invoiceLockData}
            invoiceunLockData={invoiceunLockData}
            otp={otp}
            setOtp={setOtp}
            handleOTPView={handleOTPView}
            handleOTPViewNow={handleOTPViewNow}
            invoicesLocked={invoicesLocked}
            setInvoices={setInvoices}
            // withOutOtpDownload={invoice?.is_otp_required}
            withOutOtpDownload={otpSuccess}
            invoicetoBigScreen={invoicetoBigScreen}
            lockInvoices={lockInvoices}
            unlockInvoices={unlockInvoices}
            setInvoicetoBigScreen={setInvoicetoBigScreen}
            openedInvoice={props?.openedInvoice}
          />
        </Skeleton>
      ),
    },
    {
      key: 'paymentStatus',
      label: `Payment Status`,
      children: (
        <Skeleton loading={loading}>
            <PaymentStatusTabContent from="receivables" invoice={invoice} />
        </Skeleton>
      ),
    },
    {
      key: 'bankAccount',
      label: `Bank Account`,
      children: (
        <Skeleton loading={loading}>
          {invoice.invoice_status === 'IN_REVIEW' ? (
            <UnderVerification messageText="Once invoice is verified, your virtual bank account details will reflect here. It will take upto 1 hour for verification to be completed." />
          ) : (
            <BankAccountDetailsTabContent invoice={invoice} />
          )}
        </Skeleton>
      ),
    },
  ]

  const scrollToSectionFast = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
  };

  useEffect(() => {
    if (otpViewNowBox) {
      scrollToSectionFast('come_above');
    } else {
      setOtp(['', '', '', '','',''])
    }
  }, [otpViewNowBox])

  return (
    <Modal
      className={clsx(
        classes.modal,
        `bg-[#F4F6FA] px-0 sm:px-[48px] customModal`,
        otpViewNowBox && '!overflow-hidden',
        window.innerWidth < 640 && 'h-[100%]'
      )}
    >
      {ReactDOM.createPortal(
        <Backdrop
          onClick={() => {
            if (!showFileDisplay) dispatch(modalActions.showInvoiceDisplay())
            else {
              localStorage.removeItem('zoomkaindexx')
              dispatch(modalActions.showFileDisplay())
            }
          }}
          className={classes.backdrop}
        />,
        document.getElementById('backdrop-root')
      )}
      {editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceId={selectedInvoiceId}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
      )}
      {otpViewNowBox
        && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoice} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />}
      <div className={clsx(classes.invoiceModalContainer, 'relative')} id="come_above">
        <div className="flex z-10 flex-row items-center justify-center sm:justify-between bg-[#F4F6FA] sm:pt-[48px]">
          <img
            src={leftPointer}
            alt="left-pointer"
            className="block sm:hidden absolute left-8 bg-white"
            onClick={() => {
              dispatch(modalActions.showInvoiceDisplay())
            }}
          />
          <InvoiceDetailsModalHeader
            pageName={`Invoice: ${invoice.invoice_id}`}
            arrBtn={invoice?.invoice_status?.split('_').join('').toLowerCase() === "settled" ? [...arrBtn.slice(2,3)] : [...arrBtn.slice(0,2)]}
            statusIndicators={statusIndicators}
            invoiceId={invoice.invoice_id}
            className={`w-full`}
            invoice={invoice}
            openedInvoice={props?.openedInvoice}
          />
          <Close
            className="w-[32px] h-[32px] -sm:hidden absolute top-0 right-[-48px] rounded-bl-2xl bg-gray-300"
            onClick={() => {
              dispatch(modalActions.showInvoiceDisplay())
            }}
          />
        </div>
        {invoice?.invoice_details_summary?.invoice_attributes?.remarks && <div className='flex justify-center mb-[32px] -sm:m-[2rem]'>
          <div className='flex justify-center items-center bg-[#DBDFE7] rounded-tl-[8px] rounded-bl-[8px] px-[4px]'>
            <img className='w-[20px] h-[20px]' src={quoteIcon} alt='quote-icon'/>
          </div>
          <div className='flex py-[4px] px-[16px] items-center justify-between bg-[#EAEDF3] rounded-tr-[8px] rounded-br-[8px] w-[100%]'>
            <div className='flex items-center gap-[8px]'>
              <div className='text-#1E333F text-[14px] font-[400] leading-[20px]'>
                { `Remarks${invoice?.invoice_details_summary?.invoice_attributes?.remarks_updated_at ?`(${ dayjs(invoice?.invoice_details_summary?.invoice_attributes?.remarks_updated_at).local().format(`D MMM YYYY`) })`: ""}` }: <span className='text-[#1e333f80]'>{ invoice?.invoice_details_summary?.invoice_attributes?.remarks } </span>
              </div>
              {/* <div className='text-[#1e333f80] text-[14px] font-[400] leading-[20px]'>
                
              </div> */}
            </div>
            <div className='text-#1E333F text-[14px] ml-[12px] font-medium leading-20 underline cursor-pointer'
                onClick={()=>{
                  dispatch(modalActions.editInvoice())
                  setSelectedInvoiceId(invoice?.invoice_id)
                }}
            >
              Edit
            </div>
          </div>
          </div>}
        <div
          className="flex flex-col gap-[48px] -sm:p-8"
          style={{
            background: '#F3F6FA',
          }}
        >
          <InvoiceDetailsPaymentRail
            className={`w-full`}
            buttonActive={(statusIndicators[0]?.status === "CREATED" || statusIndicators[0]?.status === "PARTIALLY_PAID" || statusIndicators[0]?.status === "LINK_SENT" || (statusIndicators[0]?.status === "IN_PROGRESS" && Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount || 0) > 0)) ? true : false}
            invoiceId={invoice.invoice_id}
            paymentLink={invoice.payment_link}
            settledWidthPercentage={(Number(invoice?.invoice_details_summary?.invoice_attributes?.settled_amount)/Number(invoice?.invoice_details_summary?.invoice_attributes?.receiving_amount)) * 100}
            inProgressWidthPercentage={(Number(invoice?.invoice_details_summary?.invoice_attributes?.in_progress_amount)/Number(invoice?.invoice_details_summary?.invoice_attributes?.receiving_amount)) * 100}
            pendingWidthPercentage={(Number(invoice?.invoice_details_summary?.invoice_attributes?.pending_amount)/Number(invoice?.invoice_details_summary?.invoice_attributes?.receiving_amount)) * 100}
            widthPercentage={statusIndicators[0]?.status === "SETTLED" ? 100 : (Number(invoice?.invoice_details_summary?.invoice_attributes?.in_progress_amount)/Number(invoice?.invoice_details_summary?.invoice_attributes?.receiving_amount)) * 100}
            amountDetail={[
              {
                text: "Receivable Amount",
                value: `${currencySign}${formatNumberToLocaleString((invoice?.invoice_details_summary?.invoice_attributes?.receiving_amount), 2, 2)}`,
                circle: "no"
              },
              {
                text: "Settled",
                value: `${currencySign}${formatNumberToLocaleString((invoice?.invoice_details_summary?.invoice_attributes?.settled_amount), 2, 2)}`,
                circle: "#38B565"
              },
              {
                text: "In Progress",
                value: `${currencySign}${formatNumberToLocaleString((invoice?.invoice_details_summary?.invoice_attributes?.in_progress_amount), 2, 2)}`,
                circle: "#1E333F80"
              },
              {
                text: "Pending",
                value: `${currencySign}${formatNumberToLocaleString((invoice?.invoice_details_summary?.invoice_attributes?.pending_amount), 2, 2)}`,
                circle: "#FF5151"
              }
            ]}
          />
        </div>
        <div
          className="flex flex-col gap-[48px] -sm:p-8"
          style={{
            background: '#F3F6FA',
          }}
        >
          <Tabs
            defaultActiveKey={defaultActiveKey}
            items={items}
            onChange={onTabChange}
          />
        </div>
      </div>
      <ButtonSet arrBtn={invoice?.invoice_status?.split('_').join('').toLowerCase() === "settled" ? [...arrBtn.slice(2,3)] : [...arrBtn.slice(0,2)]} />
    </Modal>
  )
}

export default InvoiceDetailsModal