import React, {useEffect, useState} from 'react';
import ComplianceTable from "./ComplianceChecksTable";
// import threeBlackDots from '../../../assets/Icons/threeBlackDots.svg'
// import quickSearch from '../../../assets/Icons/quickSearch.svg'
// import customSearch from '../../../assets/Icons/search-icon.svg'
import {useDispatch, useSelector} from "react-redux";
// import ReactDOM from "react-dom";
// import InvoiceDetailsModal from "../InvoiceDetailsModal/InvoiceDetailsModal";
// import {modalActions} from "../../../Store/modal-redux";
import useClickOutside from "../../../hooks/useClickOutside";
import UseApiCall from "../../../hooks/useApiCall";
import {apiGetComplianceDetails, apiGetCustomerDetails} from "../../../services";
// import CustomSearch from "./CustomSearch";
// import {CustomersActions} from "../../../Store/customer-redux";


const ComplianceChecks = (props) =>{
    const [checkAgainOpen,setCheckAgainOpen] = useState(false);
    const dispatch = useDispatch();
    const showComplianceCustomSearch = useSelector((state) => state.modal.showComplianceCustomSearch);
    const optRefCheck = useClickOutside(()=>setCheckAgainOpen(false));

    const tableHeader = [
        // 'created on', 'invoice id', 'amount', 'status',
        {
            id:1,
            title:"Search Id",
            width: 10,
            minWidth:80,
        },
        {
            id:2,
            title:"Search Date",
            width: 10,
            minWidth:80,
        },
        {
            id:3,
            title:"Search Term",
            width: 10,
            minWidth:100,
        },
        {
            id:4,
            title:"References",
            title2:"Found",
            width: 8,
            minWidth:60,
        },
        {
            id:5,
            title:"status",
            width: 10,
            minWidth:50,
        },
        {
            id:6,
            title:"Risk Level",
            width: 8,
            minWidth:60,
        },
        // {
        //     id:6,
        //     title:"Monitor",
        //     width: 14,
        //     minWidth:100,
        // },
        {
            id:7,
            title:"Action",
            width: 5,
            minWidth:20,
        },
    ]

    const [getComplianceDetails, fetchingCom,ComplianceData] = UseApiCall(apiGetComplianceDetails, (res) => {
        // console.log(res?.data)
        // dispatch(CustomersActions.setSelectedCustomer(res?.data))
        // setSelectedCustomer(res?.data)
        // !showCustomerDisplay && dispatch(modalActions.showCustomerDisplay())
    })

    useEffect(() => {
        getComplianceDetails({ customerId: props?.customerId})
    }, []);


    //
    // const totalHits = ComplianceData?.data.reduce((accumulator, currentItem) => accumulator + currentItem.totalHits, 0);

    // console.log(totalHits); // Output: 500
    // console.log('detailsvik',props.selectedCustomerId)
    // console.log('ComplianceData',ComplianceData)

    return(
        <>
            <ComplianceTable
                tableHeader={tableHeader}
                tableData={ComplianceData?.data}
                loading={fetchingCom}
            />
        </>
    )
}

export default ComplianceChecks;