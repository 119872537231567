// eslint-disable-next-line no-unused-vars
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Admin from "Layout/Admin";
import Authentication from "Layout/Authentication";
import BankAccounts from "Pages/Admin/BankAccounts";
import Cashflow from "Pages/Admin/Cashflow";
import Customers from "Pages/Admin/Customers";
import Dashboard from "Pages/Admin/Dashboard";
import Financing from "Pages/Admin/Financing";
import Login, { action as loginAction } from "Pages/Authentication/Login";
import Payouts from "Pages/Admin/Payouts";
import Receivables from "Pages/Admin/Receivables";
import Reports from "Pages/Admin/Reports";
import FxRatesPage from 'Pages/Admin/FxRatesPage'
import Settings from 'Pages/Admin/Settings'
import SignUp from 'Components/molecule/SignUp'
import Vendors from 'Pages/Admin/Vendors'
import 'Scss/global.scss'
import ForgotPassword from 'Pages/Authentication/ForgotPassword'
import PasswordSet, {
  action as passwordSetAction,
} from 'Pages/Authentication/PasswordSet'
import Error from 'Components/Global/Error'
import './index.css'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { createRef, useEffect, useState } from 'react'
import CloseIcon from 'assets/Icons/close-white.svg'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import dayjs from 'dayjs'
import { RecoilRoot } from 'recoil'
import { ConfigProvider } from 'antd'
import OtherPagesWrapper from 'Layout/OtherPagesWrapper/OtherPagesWrapper'
import MakePayment from 'Pages/Other/MakePayment/MakePayment'
import TotalPaymentTable from 'Pages/Other/CustomerPaymentPage/MakePayment'
import BusinessDetails from "Components/molecule/SignUpAdd/BusinessDetails";
import Profile from "Pages/Admin/profile/Profile";
import Compliance from "Pages/Admin/Compliance";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import ReceivablesDraft from "./Pages/Admin/ReceivablesDraft";
import TermsAndConditions from "./Pages/Admin/TermsAndConditions";
import ChatBot from "Components/Bot/ChatBot";
import { useSelector } from "react-redux";


dayjs.extend(dayjsPluginUTC)

// import {loader as receivablesLoader} from 'Pages/Admin/Receivables';

function App() {
  const notistackRef = createRef();
  const { enqueueSnackbar } = useSnackbar();
  const onClickDismiss = (key) => {
    notistackRef?.current?.closeSnackbar(key);
  };
  const chatBotDisplay = useSelector((state) => state.modal.chatBotDisplay)

  // const decryptValue = (encryptedValue) => {
  //   const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // };

  // const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  // const adminRoutes = [];

  // if (storedUserRole === 'malik') {
  //   adminRoutes.push(
  //     { path: 'bank-accounts', element: <BankAccounts /> },
  //     { path: 'profile-settings', element: <Profile /> },
  //   );
  // }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Authentication />,
      errorElement: <Error />,
      children: [
        { path: '/', element: <Navigate to="login?mode=email" replace /> },
        { path: 'login', element: <Login />, action: loginAction },
        { path: 'sign-up', element: <SignUp /> },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'set-password',
          element: <PasswordSet text="Set" email="rahul@gmail.com" />,
        },
        {
          path: 'reset-password',
          element: <PasswordSet text="Reset" email="rahul@gmail.com" />,
        },
        {
          path: 'update-password',
          element: <PasswordSet text="Update" />,
          action: passwordSetAction,
        },
      ],
    },
    {
      path: '/',
      element: <OtherPagesWrapper />,
      errorElement: <Error />,
      children: [
        { path: 'make-payment', element: <MakePayment /> },
        {
          path: 'single-payment-link/:token/:exporterLegalName/:customerId/:customerLegalName/:currencyCode',
          element: <TotalPaymentTable />,
        },
        {
          path: 'single-payment-link/:token/:exporterLegalName/:customerId/:customerLegalName',
          element: <TotalPaymentTable />,
        },
        {
          path: 'payment-link/:token/:exporterLegalName/:customerLegalName/:invoiceId',
          element: <MakePayment />,
        },
        { path: '/onboarding/details/:id',element: <BusinessDetails /> },
        { path: '/terms-and-conditions', element: <TermsAndConditions/> }
      ],
    },
    {
      path: '/',
      // element: <Admin />,
      element: (
        <>
          <Admin />
          {
            chatBotDisplay ? <ChatBot /> : <></>
          }
        </>
      ),
      errorElement: <Error />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'receivables', element: <Receivables /> },
        { path: 'receivablesDraft', element: <ReceivablesDraft /> },
        {
          path: 'receivables/:section',
          element: <Receivables />,
        },
        { path: 'customers', element: <Customers /> },
        //   { path: "payouts", element: <Payouts /> },
        //   { path: "vendors", element: <Vendors /> },
        { path: 'bank-accounts', element: <BankAccounts /> },
        { path: 'fx-rates', element: <FxRatesPage /> },
        { path: 'reports', element: <Reports /> },
        { path: 'payouts', element: <Payouts /> },
        //   { path: "cashflow", element: <Cashflow /> },
        //   { path: "financing", element: <Financing /> },
        //   { path: "settings", element: <Settings /> },
        { path: "profile-settings", element: <Profile /> },
        { path: "compliance", element: <Compliance /> }
        // ...adminRoutes,
      ],
    },
  ])

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorTextPlaceholder: "rgba(30, 51, 63, 0.5)",
            fontFamily: 'Inter',
            fontSizeLG: '1.6rem',
            fontWeightStrong: '500',
            colorText: '#1E333F',
            colorInfoBorderHover: '#1E333F',
            colorInfoBorder: '#1E333F',
            colorSuccessBorder: '#1E333F'
          },
        },
      }}
    >
      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={2000}
        action={(key) => (
          <img
            width={20}
            height={20}
            src={CloseIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              onClickDismiss(key);
            }}
          />
        )}
        maxSnack={1}
        className="text-xl"
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <RecoilRoot>
          <RouterProvider router={router} />
        </RecoilRoot>
      </SnackbarProvider>
    </ConfigProvider>
  );
}

export default App;
