import React, { useEffect, useState } from "react";
import messageIcon from "assets/Icons/message-square.svg";
import ChatModal from "./ChatModal";

import classes from "./ChatBot.module.scss"; // Import SCSS module

const ChatBot = ({ }) => {
    const [showModal, setShowModal] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [messages, setMessages] = useState([
        { type: "admin", status: "", action: "", message: "How can I help you? Sir!", doc_format: "", file_url: "", missing_fields: [], data: null },
        // { type: "admin", status: "", action: "", message: "Fideo समाज की ओर से सभी छत्तीस बिरादरी को राम राम। बड़े भाईसाब की कैसे मदद कर सकता हूं!", missing_fields: [], data: null },
        // { type: "admin", status: "", action: "", message: "Hi there!", missing_fields: [], data: null },
        // { type: "user", status: "", action: "", message: "How are you?", missing_fields: [], data: null },
        // { type: "admin", status: "", action: "", message: "I'm good, thank you!", missing_fields: [], data: null },
    ]);

    useEffect(() => {
        if (showModal && threadId === null)
            fetch('https://api.openai.com/v1/threads', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_CHATBOT_TOKEN,
                    'OpenAI-Beta': 'assistants=v1',
                },
            })
                .then(response => response.json())
                .then(data => {
                    setThreadId(data?.id);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    }, [showModal])

    return (
        <div>
            <div className={classes.iframe} style={{ display: showModal ? "none" : "" }}>
                <button className={classes.button} onClick={() => setShowModal(!showModal)}>
                    <img src={messageIcon} alt="message-icon" className={classes.icon} />
                </button>
            </div>
            {showModal && <ChatModal onClose={() => setShowModal(false)} threadId={threadId} messages={messages} setMessages={setMessages} />}
        </div>
    );
};

export default ChatBot;
