import React from 'react';
import classes from "../../../Pages/Admin/profile/profileComponents/AccessTooltip.module.scss";
import tooltipIcon from "assets/Icons/Rectangle 123.svg"
import dayjs from "dayjs";
import clsx from "clsx";

const CompTooltip = (props) => {

    // console.log('props.SearchData',props.SearchData)
    return (
        <div className={classes.CompTooltipCon}>
            <div className={classes.CompTooltipConTop} style={{marginBottom:"-1px"}}>
                <img src={tooltipIcon} className={clsx(classes.tipImg,'w-[16px] h-[12px] ')} />
            </div>
            <div className={classes.CompTooltipConBottom}>
                <div className={classes.CompreceivableCon}>
                    <div className={classes.field}>
                        <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase ">Reference
                            ID</p>
                        <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{props.SearchData?.searchId ? props.SearchData?.searchId : "--"}</p>
                    </div>

                    <div className="w-[100%] h-[1px] bg-[#ECEFF21F]"></div>

                    <div className={classes.field}>
                        <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase">Date</p>
                        <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{props.SearchData?.searchDate ? dayjs(props.SearchData?.searchDate).local().format('DD MMM YYYY') : "--"}</p>
                    </div>

                    <div className="w-[100%] h-[1px] bg-[#ECEFF21F]"></div>

                    <div className={classes.field}>
                        <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase">Customer
                            Name</p>
                        <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{props.SearchData?.searchTerm ? props.SearchData.searchTerm : "--"}</p>
                    </div>

                    <div className="w-[100%] h-[1px] bg-[#ECEFF21F]"></div>

                    {/*<div className={classes.field}>*/}
                    {/*    <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase">Year*/}
                    {/*        of Birth</p>*/}
                    {/*    <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{`--`}</p>*/}
                    {/*</div>*/}

                    {/*<div className="w-[100%] h-[1px] bg-[#ECEFF21F]"></div>*/}

                    <div className={classes.field}>
                        <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase">Countries</p>
                        <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{props.SearchData?.country ? props.SearchData.country : "--"}</p>
                    </div>

                    <div className="w-[100%] h-[1px] bg-[#ECEFF21F]"></div>

                    <div className={classes.field}>
                        <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#FFFFFF99] uppercase">Fuzziness Percentage</p>
                        <p className="font-inter font-[500] text-[14px] leading-[20px] text-[#FFFFFF]">{(props.SearchData?.fuzziness !== undefined) ? props.SearchData.fuzziness * 100 : "--"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompTooltip