import React, { useEffect, useState, memo, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import FileIcon from "assets/Icons/fileIconNew.svg";
import lockGrayIcon from "assets/Icons/lockGrayIcon.svg";
import lockDoneIcon from "assets/Icons/lockDoneIcon.svg";
import DeleteIcon from "assets/Icons/delete-icon.svg";
import { useSnackbar } from 'notistack'
import CloseIcon from 'assets/Images/Close.svg'
import CloseIcon2 from 'assets/Icons/close-white-icon.svg'
import RefreshIcon from 'assets/Icons/refresh.svg'
import DoneIcon from 'assets/Icons/done-white.svg'
import { apiGetBulkTransactionsId } from 'services';
import styles from "./FileBrowserNew.module.scss";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import {
  apiCreateMediaUploadUrl,
  apiDeleteMedia,
} from "services/invoiceServices";
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';
import { baseUrl } from "constants/Network";
import { convertBase64 } from "hooks/convertBase64";

const fileFormats = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
}

const generateRandomId = (randomNumberLength) => {
  let result = ''
  while (result.length <= randomNumberLength) {
    result = `${result}${Math.round(9 * Math.random())}`
  }
  return result
}

const getAllowedFileTypes = (allowedFormats) => {
  const result = []
  allowedFormats?.forEach((types) => {
    result.push(...fileFormats[types])
  })
  return result
}

const MAX_ALLOWED_FILES = 10

const SEARCH_TEXT = "return an object which consists of these keys and their respective values [invoiceId, issueDate, dueDate, blDate, remarks, netTerms, invoiceAmount, receivingAmount, invoiceCurrency, description]\n calculate issueDate, dueDate and  blDate must be in this format DD-MM-YYYY or if not found then null. description, remarks and netTerms are text fields and invoiceCurrency is code only eg USD, EUR, GBP, INR\nJust return the object other than that nothing else\nremove json too and don't return any comments in object\n"

const BulkFileBrowser = ({
  transactionId,
  validFormats,
  isResponsive = true,
  disabled = false,
  displayUrl = [],
  setFile,
  hasError: externalHasError,
  errorMessage,
  isMobile,
  userType,
  docType,
  docNum,
  userName,
  files,
  componentName,
  setOcrResponse,
  setOcrLoading,
  setSaveDisabled,
  saveDisabled,
  setTransactionId,
  setSelectedFiles,
  selectedFiles,
  setParamsArray,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
//   const [selectedFiles, setSelectedFiles] = useState(files || {})
  const [selectedPreviewFileIdx, setSelectedPreviewFileIdx] = useState(null)
  const [deletedFiles, setDeletedFile] = useState({})
  const hiddenFileInput = React.useRef(null)
  const [currentFiles, setCurrentFiles] = useState([]);
  const [saveCurrentFiles, setSaveCurrentFiles] = useState();
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [seconds, setSeconds] = useState(false);

  const [deleteMedia] = UseApiCall(apiDeleteMedia);
  const [hasError, setHasError] = useState(externalHasError)
  let count = Object.keys(files || {})?.length;
  
  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  const totalFiles = useMemo(() => {
    if (!selectedFiles) {
      return [];
    }
  
    return Object.keys(selectedFiles).filter((id) => !deletedFiles[id]);
  }, [selectedFiles, deletedFiles]);

  useEffect(() => {
    setFile(saveCurrentFiles)
  }, [saveCurrentFiles])

  useEffect(() => {
    if (selectedFiles && deletedFiles) {
      const filteredFiles = Object.keys(selectedFiles).filter((file) => {
        return !deletedFiles[file];
      });
      setCurrentFiles(filteredFiles);
    }
  }, [totalFiles]);

  useEffect(() => {
    if (selectedFiles) {
      const newObject = Object.keys(selectedFiles).reduce((result, key) => {
        if (currentFiles.includes(key)) {
          result[key] = selectedFiles[key];
        }
        return result;
      }, {});
  
      setSaveCurrentFiles(newObject);
    }
  }, [selectedFiles, currentFiles]);

  const onSelectFiles = (files) => {
    setSaveDisabled(true);
    if (files?.length > 0) {
      setSeconds(4*files?.length)
      const newFiles = {}
      ;[...files].forEach((f) => {
        const id = generateRandomId(6)
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: true,
          isUploading: null,
          isAnalyzing: null,
          txnId: '',
          showText: '',
          id,
        })
      })

      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
      uploadFiles(newFiles)
      setUploadingLoader(true)
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      const maxSize = 10 * 1024 * 1024;
  
      const oversizedFiles = acceptedFiles?.filter((file) => file?.size > maxSize);
      const notOversizedFiles = acceptedFiles?.filter((file) => file?.size < maxSize);
  
      if (oversizedFiles?.length > 0) {

        if(notOversizedFiles?.length > 0){
          onSelectFiles(notOversizedFiles)
        }

        oversizedFiles?.forEach((f, index) => {
          setTimeout(() => {
            enqueueSnackbar(`This File ${f?.path} size exceeds 10MB:  ${(f?.size / (1024 * 1024))?.toFixed(4)} MB`, {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 3000,
            });
          }, index * 3000);
        });
        
      } else {
        onSelectFiles(acceptedFiles)
      }
    },
    disabled: disabled,
  });

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    return () =>
      Object.keys(selectedFiles).forEach((fileId) =>
        URL.revokeObjectURL(selectedFiles[fileId].preview)
      )
  }, [])

  const uploadFiles = async (newFiles) => {
    const sortedFiles = Object.values(newFiles).sort(
      (a, b) => a.uploadedAt - b.uploadedAt
    )
    const fileIds = sortedFiles.map((a) => a.id)
    if (!selectedPreviewFileIdx) {
      setSelectedPreviewFileIdx(fileIds[0])
    }
      
    for (let i = 0; i < fileIds.length; i++) {
      if (deletedFiles[fileIds[i]]) {
        continue
      }
      const _file = newFiles[fileIds[i]]
      try {
        _file.isUploading = true
        _file.isAnalyzing = true
        _file.showText = "Uploading..."
        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))

        // File upload process started
        const arrayBuffer = await _file.arrayBuffer()
        const blob = new Blob([arrayBuffer], {
          type: _file.type,
        })

        let apiResponse;
        try {
            let fileName = encodeURIComponent(_file?.name)
            count++;

            let transactionIdRes = await apiGetBulkTransactionsId();
            const transactionIdResponse = doEncryption ? handleDecrypt(transactionIdRes?.data) : transactionIdRes?.data;
            setTransactionId(transactionIdResponse?.data)
            _file.txnId = transactionIdResponse?.data
            apiResponse = await apiCreateMediaUploadUrl({
                txnId: transactionIdResponse?.data,
                fileName: fileName,
            });
        } catch (error) {
            enqueueSnackbar(`${JSON.stringify(error?.data?.error_response?.message)}`, { variant: 'error' })
        }

        let urlData;
        if (doEncryption) {
            urlData = await handleDecrypt(apiResponse?.data);
            await fetch(urlData?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
        } else {
            urlData = await apiResponse;
            await fetch(urlData?.data?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
        }

        // File uploaded
        _file.inQueue = false
        _file.isUploading = false
        _file.showText = "Analyzing..."
        if (doEncryption) {
            _file.linkId = urlData?.data?.invoiceLinkId
        } else {
            _file.linkId = urlData?.data?.data?.invoiceLinkId
        }

        try {
          setOcrLoading(true);

          let chatRes = {};

          if (sortedFiles[i]?.type.startsWith('image/')) {
            console.log('Image file detected');
            const BASE64_STRING = await convertBase64(sortedFiles[i]);
            const data = {
              "model": "gpt-4-vision-preview",
              "messages": [
                {
                  "role": "user",
                  "content": [
                    {
                      "type": "text",
                      "text": `${SEARCH_TEXT}`
                    },
                    {
                      "type": "image_url",
                      "image_url": {
                        "url": `${BASE64_STRING}`,
                         "detail": "high"
                      }
                    }
                  ]
                }
              ],
              "max_tokens": 3000
            };
          
            await fetch(baseUrl.api + `/proxy?docType=IMAGE`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(data)
            })
            .then(async (response) => {
              let responseData;
              if (doEncryption) {
                responseData = await response.text();
              } else {
                responseData = await response.json();
              }
              let res = doEncryption ? handleDecrypt(responseData) : responseData;
              return res;
            })
            .then(async (res) => {
                chatRes = await JSON.parse(res?.data);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
              setOcrLoading(false);
            })
          } else {
            const formData = new FormData();
            formData.append('file', sortedFiles[i]);
            // formData.append('organization_id', 8);
  
            const analyzeValues = await fetch(baseUrl.api + `/analyze/invoice`, {
              method: 'POST',
              body: formData,
              credentials: 'include',
            });
  
            let response;
            if (doEncryption) {
              response = await analyzeValues.text();
            } else {
              response = await analyzeValues.json();
            }

            console.log(response, "text_response");

            let res = doEncryption ? handleDecrypt(response) : response;
            if (doEncryption) {
              response = await res?.data;
            } else {
              response = await response?.data;
            }
    
            // const requestData = {
            //   model: "gpt-4-turbo-preview",
            //   messages: [
            //     {
            //       role: "user",
            //       content: `\n ${response}\n\n ${SEARCH_TEXT}`
            //     }
            //   ],
            //   temperature: 1,
            //   max_tokens: 1200,
            //   top_p: 1,
            //   frequency_penalty: 0,
            //   presence_penalty: 0
            // };
            
            await fetch(baseUrl.api + `/proxy?docType=PDF`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(response)
            })
            .then(async (response) => {
              let responseData;
              if (doEncryption) {
                responseData = await response.text();
              } else {
                responseData = await response.json();
              }
              let res = doEncryption ? handleDecrypt(responseData) : responseData;
              return res;
            })
            .then(async (res) => {
              chatRes = await JSON.parse(res?.data);
            })
            .catch(error => {
              console.error('Error:', error);
            })
            .finally(() => {
              setOcrLoading(false);
            })
          }

          const transactionIdResponseData = _file.txnId;
          chatRes.txnId = transactionIdResponseData;
          const updatedObject = chatRes;
          setOcrResponse([updatedObject]);
        
        } catch (error) {
          console.log(error)
        }

        _file.isAnalyzing = false
        _file.showText = "Analyzed"
        
        if (deletedFiles[_file.id]) {
        }

        setTimeout(() => {
          if (seconds === 0) {
            setUploadingLoader(false)
            setSeconds(0)
          }
        }, [2000])

        setSeconds(prev => prev - 4)

        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))
      } catch (e) {
        handleRemoveFile(_file)
      }
    }
    setSaveDisabled(false);
  }

  const onUploadClick = (event) => {
    if (disabled) return
    hiddenFileInput.current.click()
  }

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    onSelectFiles(event.target.files)
  }

  const handleRemoveFile = async (file) => {
    const currentFiles = Object.values(selectedFiles)
      .filter((f) => !deletedFiles[f.id])
      .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)

    const delFileIdx = currentFiles.findIndex((f) => f.id === file.id)

    if (file.id === selectedPreviewFileIdx) {
      if (delFileIdx < currentFiles.length - 1) {
        const _id = currentFiles[delFileIdx + 1].id
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      } else {
        const _id =
          currentFiles.length > 1
            ? currentFiles[currentFiles.length - 2].id
            : null
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      }
    } else if (selectedPreviewFileIdx && deletedFiles[selectedPreviewFileIdx]) {
      const _id =
        currentFiles.length > 1
          ? currentFiles[currentFiles.length - 2].id
          : null
      setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
    }
    setParamsArray(prevParamsArray => {
        return prevParamsArray.filter(obj => obj.transactionId !== file.txnId);
    });
    if ((componentName === "locked" || componentName === "unLocked") && file.linkId) {
      deleteMedia({ txnId: transactionId, invoiceLinkId: file.linkId })
    } else{
      deleteMedia({ invoiceLinkId: file.linkId, txnId: file.txnId })
    }
    setDeletedFile((prev) => ({ ...prev, [file.id]: file.id }))
  }

  return (
    <div>
      <div className="" style={{
        width: "100%"
      }}>
        <div className={clsx(styles.documentupload, '')}>
          <div
            {...getRootProps({
              className: clsx(
                styles.dropContainer,
                'dropzone',
              ),
            })}
            style={{}}
          >
            {(
              <div
                className={clsx(styles.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
              >
                <div className={clsx(`relative w-[84px] ${isMobile ? "h-[44px]" : "h-[84px] bottom-[-6px]"}`)}>
                  <img src={componentName === "locked" ? lockGrayIcon : FileIcon} alt="file" className={styles.fileIconImg} />
                  {/* {componentName !== "locked" &&(<div className={clsx(styles.arrowBackground)}>
                    <img src={ArrowIcon} alt="arrow" className="w-[24px] h-[24px]" />
                  </div>)} */}
                </div>
                <p className="mt-[16px]">
                  { false ?
                      <div className={clsx(styles.fileText, `ml-4`)}><b className="font-semibold">Browse files</b></div>
                      : (isDragActive ? (
                          <><b className="font-semibold">Drag</b> files here to upload{' '}</>
                          ) : (
                          <>
                          { !isMobile
                              ? <p className={styles.fontText}>Drop files here to upload, or click <b className={styles.fontTextUpload}>Upload</b></p>
                              : <p className={styles.fontText}>Tap here to <b className={styles.fontTextUpload}>Upload</b></p>
                          }
                            <p className={styles.fontSubText}>PDF, JPEG, JPG, PNG (max 10mb)</p>
                          </>
                          )
                        )
                  }
                </p>
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        </div>
        <div
          className={clsx(
            styles.thumbnailContainer,
            isMobile && 'h-[30vh] overflow-y-scroll overflow-x-hidden'
          )}
          style={{ display: totalFiles?.length === 0 && "none"}}
        >
          {componentName === "locked" ? 
          (<p className={clsx(styles.fontText, 'font-[500]')}>
            {
              (uploadingLoader && seconds > 0)
                ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} documents, ${seconds} seconds left`
                : `Uploaded ${Object.keys(files || {})?.length} ${Object.keys(files || {})?.length <= 1  ? 'locked document' : 'locked documents'}`
            }
          </p>) :
          (<p className={clsx(styles.fontText, 'font-[500]')}>
          {
            (uploadingLoader && seconds > 0)
              ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} invoices, ${seconds} seconds left`
              : `Uploaded ${Object.keys(files || {})?.length} ${Object.keys(files || {})?.length <= 1  ? 'invoice' : 'invoices'}`
          }
          </p>)
          }

          <div className={"mt-[10px] mb-[12px] relative left-[-24px]"} style={{ border: "1px solid #ECEFF2", background: "#FFF", width: "calc(100% + 48px)" }}></div>
          {totalFiles?.length > 0 &&
            Object.values(selectedFiles)
              .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)
              .map((file) =>
                deletedFiles[file.id] ? null : (
                  <div className={styles.fileUploader}>
                    <div className="flex items-center">
                      {
                        file.inQueue
                          ? 
                          <img src={RefreshIcon} alt="RefreshIcon" className='' />
                          : 
                          (componentName !== "locked" ?
                            (<div className={clsx(file.showText.includes("Failed") ? `bg-[#b80c0c]` : `bg-[#1CA95D]`, `h-[22px] w-[22px] p-[2px] rounded-full relative`)}>
                                {
                                    file.showText.includes("Failed")
                                        ?
                                            <img src={CloseIcon2} alt="CloseIcon" className='bg-[#b80c0c] h-[12px] w-[12px] rounded-full relative top-[22%] left-[15%]' />
                                        :
                                            <img src={DoneIcon} alt="DoneIcon" className='bg-[#1CA95D] h-[12px] w-[12px] rounded-full relative top-[22%] left-[15%]' />
                                }
                          </div>) : <img src={lockDoneIcon} alt="RefreshIcon" className='pr-[16px]' />)
                      }
                    </div>
                    <div
                      key={file.id}
                      className={clsx(
                        styles.fileThumbnail,
                        file.inQueue ? 'opacity-40' : ''
                      )}
                      onClick={() => setSelectedPreviewFileIdx(file.id)}
                    >
                      {/* {fileFormats.document.includes(file.type) && (
                        <Document
                          file={file.preview}
                          className={clsx(styles.documentThumbnail)}
                        >
                          <Page pageNumber={1} />
                        </Document>
                      )} */}
                      <div className={styles.uploadLayout}>
                        <p className={clsx(styles.documentText, 'mb-[4px]')}>{file?.path?.length > 40  ? `${file.path.slice(0, 40)}...` : file.path}</p>
                        {/* <p className={styles.uploadingText}>{file.isUploading === false ? (file.isAnalyzing === false ? "Creating Draft" : "Analyzing...") : "Uploading..."}</p> */}
                        <p className={styles.uploadingText}>{file.showText}</p>
                        <p></p>
                      </div>
                      <div
                        className={clsx(
                          styles.progressBar,
                          file.isUploading === false
                            ? styles.progressBar100
                            : file.isUploading === true
                            ? styles.progressBar60 : file.isUploading === true ? styles.progressBar20
                            : ''
                        )}
                      ></div>
                    </div>
                    {!file.inQueue && !disabled && (
                        <div
                          className={clsx(styles.closeIcon)}
                          onClick={() => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                              ? <img src={CloseIcon} alt="CloseIcon" className='' />
                              : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                    {file.inQueue && (
                        <div
                          className={clsx(styles.closeIcon)}
                          onClick={() => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                            ? <img src={CloseIcon} alt="CloseIcon" className='' />
                            : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                  </div>
                )
              )}
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUploadChange}
          multiple
          accept={getAllowedFileTypes(validFormats).join(', ')}
          style={{ display: 'none' }}
        />
      </div>
      {/* code to check if error add message */}
      {hasError && <div className={styles.error} style={{ width: "100%"}}>{errorMessage}</div>}
    </div>
  )
}

export default memo(BulkFileBrowser);