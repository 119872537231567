import urlIcon from "assets/Icons/urLIcon.svg"
const AdverseMediaHit = (props) => {

    // console.log('props.hitData?.doc?.media',props.hitData?.doc?.media)
    return(
        <>
            <div className="flex flex-col rounded-[12px] bg-[#FFFFFF]">
                {props.hitData?.doc?.media?.length && props.hitData?.doc?.media?.map((media,index)=> (
                    <div className="flex border-b-[1px] border-[#ECEFF2] p-[16px] md:p-[32px]" key={index}>
                        <div className="flex gap-[16px] items-start">
                            <div
                                className=" flex-col hidden md:block items-center justify-center px-[12px] py-[6px] rounded-full border-[2px] border-[#ECEFF2] ">
                                <p className="font-inter font-[500] text-[14px] min-w-[24px] text-center md:text-[16px] leading-[24px] text-[#1E333F]">{media.date ? new Date(media.date).getDate() : "--"}</p>
                                <p className="font-inter font-[600] text-[10px] min-w-[24px] text-center md:text-[12px] leading-[12px] text-[#1E333F66]">{media.date ? new Date(media.date).toLocaleString('en-US', {month: 'short'}) : "--"}</p>
                            </div>
                            <div className="flex flex-col gap-[12px] ">
                                {/*title*/}
                                <div className="flex flex-col gap-[4px] ">
                                    <div className="flex items-start md:items-center gap-[8px] ">
                                        <p className="font-inter font-[600] text-[14px] md:text-[16px] leading-[24px] text-[#1E333F]">{media?.title ? media?.title : '--'}</p>
                                        {media?.url &&
                                            (<div className="w-[40px] md:w-[16px] h-[40px] md:h-[16px]">
                                            <a href={media?.url} target="_blank">
                                                <img src={urlIcon} alt="urlIcon"
                                                     style={{width: "16px", height: "16px"}}/>
                                            </a>
                                        </div>)}
                                    </div>
                                    <div className="flex items-center gap-[4px]">
                                        {/* Format and display the date */}
                                        <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F66]">
                                            {media?.date ? new Date(media.date).toLocaleDateString('en-US', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric'
                                            }) : "--"
                                            }
                                        </p>
                                        {/* Divider */}
                                        {
                                            media?.date && <div className="w-[3px] h-[3px] rounded-[100px] bg-[#1E333F66]"></div>
                                        }
                                        {/* Format and display the time */}
                                        <p className="font-inter font-[400] text-[12px] leading-[18px] text-[#1E333F66]">
                                            {media?.date ? new Date(media.date).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            }) : "--"
                                            }
                                        </p>
                                    </div>

                                </div>

                                {/*snippet*/}
                                <div
                                    className="flex rounded-[12px] border-l-[2px] border-[#6F91CD] p-[20px] bg-[#F4F8FF] ">
                                    <p className="font-inter font-[400] min-w-fit text-[14px] md:text-[14px] leading-[24px] text-[#1E333F]">{media?.snippet}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {(props.hitData?.doc?.media === undefined || props.hitData?.doc?.media === null || props.hitData?.doc?.media?.length === 0)  &&
                    <div className="flex items-center justify-center p-[20px]">No adverse media found.</div>
                }
            </div>
        </>
    )
}

export default AdverseMediaHit;