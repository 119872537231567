import {Link, NavLink} from "react-router-dom";
import classes from "Components/General/SideBar.module.scss";
// import dashboardIcon from "assets/Icons/dashboard-icon.svg";
// import payoutsIcon from "assets/Icons/payouts-inactive.svg";
// import customersIcon from "assets/Icons/customer-icon.svg";
import vendorsIcon from "assets/Icons/vendor-icon.svg";
// import reportsIcon from "assets/Icons/report-icon.svg";
import loanIcon from "assets/Icons/finance-icon.svg";
// import bankAccountsIcon from "assets/Icons/bankaccount-icon.svg";
import fxRatesIcon from 'assets/Icons/fxrates-inactive.svg'
import fxRatesActiveIcon from 'assets/Icons/fxrates-active.svg'
import settingsIcon from "assets/Icons/settings-icon.svg";
import cashflowIcon from "assets/Icons/cashflow-icon.svg";
import ReactDOM from "react-dom";
import Backdrop from "Layout/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActions } from "Store/global-redux";
import complianceWhiteIcon from "assets/Icons/compliance-icon.svg";
import complianceIcon from "assets/Icons/compliance-icon-inactive.svg";
import DraftIcon from "assets/Icons/all&DraftIcon.svg";
import triActive from "assets/Icons/Rectangle 123 right.svg";
// import reportsWhiteIcon from "assets/Icons/reports-white-icon.svg";
// import dashboardWhiteIcon from "assets/Icons/dashboard-white-icon.svg";
// import receivablesIcon from "assets/Icons/receivable-inactive.svg";
// import receivablesWhiteIcon from "assets/Icons/receivable-active.svg";
// import customersWhiteIcon from "assets/Icons/customers-white-icon.svg";
import cashflowWhiteIcon from "assets/Icons/cashflow-white-icon.svg";
import financingWhiteIcon from "assets/Icons/financing-white-icon.svg";
// import bankAccountWhiteIcon from "assets/Icons/bankaccounts-white-icon.svg";
import settingsWhiteIcon from "assets/Icons/settings-white-icon.svg";
// import payoutsWhiteIcon from "assets/Icons/payout-active.svg";
import vendorsWhiteIcon from "assets/Icons/vendor-white-icon.svg";
// import profileActiveIcon from "assets/Icons/profileWhite.svg";
// import profileIcon from "assets/Icons/profile.svg";
import fideoLogo from "assets/Images/FideoLogo.svg";
import TourStartHeader from "Components/Tour/TourStartHeader";
import TourStartPrompt from "Components/Tour/TourStartPrompt";
import { useEffect, useState } from "react";
import clsx from "clsx";
import CryptoJS from 'crypto-js';
import airplay from "assets/Icons/airplayIcon.svg"
import chevronRight from "assets/Icons/chevron-rightNew.svg";
import chevronUp from "assets/Icons/chevron-upNew.svg";
import chevronDown from "assets/Icons/chevron-downNew.svg";
import { secretKey } from "constants";
import { modalActions } from "Store/modal-redux";
import { apiShowChatBotModal } from "services";
import UseApiCall from "hooks/useApiCall";

// const storedUserRole = localStorage.getItem('resetKeyencrypt');



const SideBar = (props) => {
  const dispatch = useDispatch();
  const tourStart = useSelector((state) => state.modal.tourStart);
  // const [selectedOption, setSelectedOption] = useState(1);
  const [isReceivablesOpen, setIsReceivablesOpen] = useState(false);
  const showSideBarTablet = useSelector(
    (state) => state.global.showSideBarTablet
  );
  const showSideBarMobile = useSelector(
    (state) => state.global.showSideBarMobile
  );

  const selectedOption = useSelector(
    (state) => state.global.selectedOption
  );

  const subMenu = useSelector((state) => state.global.subMenu);

  // console.log('selectedOption',selectedOption)

  // const [subMenu,setSubMenu] = useState("all")

  const [showChatModal] = UseApiCall(apiShowChatBotModal, (res) => {
    console.log(res?.data, res?.data === true, res?.data == true, "chat modal")
    if (res?.data == true) dispatch(modalActions.chatBotDisplay());
}, (err) => {
    console.error("Error while fetching chat modal data : ", err);
})

  useEffect(() => {
    showChatModal({configKey: "chatBotToggle"});
  }, [])

  const navLinkClickHandler = () => {
    dispatch(GlobalActions.setShowSideBarMobile(false));
    dispatch(GlobalActions.setShowSideBarTablet(false));
  };

  const optionHandler = ({ isActive, id,navName }) => {
    // if (isActive) setSelectedOption(id);
    // if(navName !== "Receivables" && subMenu === "draft"){
    //   setSubMenu("all")
    // }
    if (isActive) dispatch(GlobalActions.setSelectedOption(id));

    // if(navName === "Receivables"){
    //   return (isActive )
    //       ? `${classes.activeReceivable} ${showSideBarTablet && classes.active_open}`
    //       : undefined;
    // }else {
    //   return (isActive)
    //       ? `${classes.active} ${showSideBarTablet && classes.active_open}`
    //       : undefined;
    // }

    return (isActive)
        ? `${classes.active} ${showSideBarTablet && classes.active_open}`
        : undefined;
  };

  const handleSubMenu = (sub,isActive,id) => {
    // console.log('ss',sub,isActive,id)
    dispatch(GlobalActions.setSubMenu(sub));
    // setSubMenu(sub)
    // optionHandler({ isActive, id: id })
  }

  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');
  const userName = localStorage.getItem("username")
  const AuthBankSideBarList = [];
  const AuthProfileSideBarList = [];

  const svgDashboardIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2L3 9V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H7.5V12C7.5 11.1716 8.17157 10.5 9 10.5H15C15.8284 10.5 16.5 11.1716 16.5 12V22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V9L12 2ZM13.5 22V13.5H10.5V22H13.5Z" fill="#8F999F"/>
      <path d="M3 9L2.69303 8.60532C2.57124 8.70005 2.5 8.8457 2.5 9H3ZM12 2L12.307 1.60532C12.1264 1.46489 11.8736 1.46489 11.693 1.60532L12 2ZM3.58579 21.4142L3.93934 21.0607H3.93934L3.58579 21.4142ZM7.5 22V22.5C7.77614 22.5 8 22.2761 8 22H7.5ZM16.5 22H16C16 22.2761 16.2239 22.5 16.5 22.5V22ZM21 9H21.5C21.5 8.8457 21.4288 8.70005 21.307 8.60532L21 9ZM13.5 22V22.5C13.7761 22.5 14 22.2761 14 22H13.5ZM13.5 13.5H14C14 13.2239 13.7761 13 13.5 13V13.5ZM10.5 13.5V13C10.2239 13 10 13.2239 10 13.5H10.5ZM10.5 22H10C10 22.2761 10.2239 22.5 10.5 22.5V22ZM3.30697 9.39468L12.307 2.39468L11.693 1.60532L2.69303 8.60532L3.30697 9.39468ZM3.5 20V9H2.5V20H3.5ZM3.93934 21.0607C3.65804 20.7794 3.5 20.3978 3.5 20H2.5C2.5 20.663 2.76339 21.2989 3.23223 21.7678L3.93934 21.0607ZM5 21.5C4.60218 21.5 4.22064 21.342 3.93934 21.0607L3.23223 21.7678C3.70107 22.2366 4.33696 22.5 5 22.5V21.5ZM7.5 21.5H5V22.5H7.5V21.5ZM8 22V12H7V22H8ZM8 12C8 11.4477 8.44772 11 9 11V10C7.89543 10 7 10.8954 7 12H8ZM9 11H15V10H9V11ZM15 11C15.5523 11 16 11.4477 16 12H17C17 10.8954 16.1046 10 15 10V11ZM16 12V22H17V12H16ZM19 21.5H16.5V22.5H19V21.5ZM20.0607 21.0607C19.7794 21.342 19.3978 21.5 19 21.5V22.5C19.663 22.5 20.2989 22.2366 20.7678 21.7678L20.0607 21.0607ZM20.5 20C20.5 20.3978 20.342 20.7794 20.0607 21.0607L20.7678 21.7678C21.2366 21.2989 21.5 20.663 21.5 20H20.5ZM20.5 9V20H21.5V9H20.5ZM11.693 2.39468L20.693 9.39468L21.307 8.60532L12.307 1.60532L11.693 2.39468ZM14 22V13.5H13V22H14ZM13.5 13H10.5V14H13.5V13ZM10 13.5V22H11V13.5H10ZM13.5 21.5H10.5V22.5H13.5V21.5Z" fill="#8F999F"/>
    </svg>
  `;


  const svgDashboardWhiteIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2L3 9V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H7.5V12C7.5 11.1716 8.17157 10.5 9 10.5H15C15.8284 10.5 16.5 11.1716 16.5 12V22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V9L12 2ZM13.5 22V13.5H10.5V22H13.5Z" fill="white"/>
      <path d="M3 9L2.69303 8.60532C2.57124 8.70005 2.5 8.8457 2.5 9H3ZM12 2L12.307 1.60532C12.1264 1.46489 11.8736 1.46489 11.693 1.60532L12 2ZM3.58579 21.4142L3.93934 21.0607H3.93934L3.58579 21.4142ZM7.5 22V22.5C7.77614 22.5 8 22.2761 8 22H7.5ZM16.5 22H16C16 22.2761 16.2239 22.5 16.5 22.5V22ZM21 9H21.5C21.5 8.8457 21.4288 8.70005 21.307 8.60532L21 9ZM13.5 22V22.5C13.7761 22.5 14 22.2761 14 22H13.5ZM13.5 13.5H14C14 13.2239 13.7761 13 13.5 13V13.5ZM10.5 13.5V13C10.2239 13 10 13.2239 10 13.5H10.5ZM10.5 22H10C10 22.2761 10.2239 22.5 10.5 22.5V22ZM3.30697 9.39468L12.307 2.39468L11.693 1.60532L2.69303 8.60532L3.30697 9.39468ZM3.5 20V9H2.5V20H3.5ZM3.93934 21.0607C3.65804 20.7794 3.5 20.3978 3.5 20H2.5C2.5 20.663 2.76339 21.2989 3.23223 21.7678L3.93934 21.0607ZM5 21.5C4.60218 21.5 4.22064 21.342 3.93934 21.0607L3.23223 21.7678C3.70107 22.2366 4.33696 22.5 5 22.5V21.5ZM7.5 21.5H5V22.5H7.5V21.5ZM8 22V12H7V22H8ZM8 12C8 11.4477 8.44772 11 9 11V10C7.89543 10 7 10.8954 7 12H8ZM9 11H15V10H9V11ZM15 11C15.5523 11 16 11.4477 16 12H17C17 10.8954 16.1046 10 15 10V11ZM16 12V22H17V12H16ZM19 21.5H16.5V22.5H19V21.5ZM20.0607 21.0607C19.7794 21.342 19.3978 21.5 19 21.5V22.5C19.663 22.5 20.2989 22.2366 20.7678 21.7678L20.0607 21.0607ZM20.5 20C20.5 20.3978 20.342 20.7794 20.0607 21.0607L20.7678 21.7678C21.2366 21.2989 21.5 20.663 21.5 20H20.5ZM20.5 9V20H21.5V9H20.5ZM11.693 2.39468L20.693 9.39468L21.307 8.60532L12.307 1.60532L11.693 2.39468ZM14 22V13.5H13V22H14ZM13.5 13H10.5V14H13.5V13ZM10 13.5V22H11V13.5H10ZM13.5 21.5H10.5V22.5H13.5V21.5Z" fill="white"/>
    </svg>
  `;

  const dashboardIcon = `data:image/svg+xml;base64,${btoa(svgDashboardIcon)}`;
  const dashboardWhiteIcon = `data:image/svg+xml;base64,${btoa(svgDashboardWhiteIcon)}`;

  const svgReceivablesIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V3C23 1.89543 22.1046 1 21 1H3ZM12 4C12.5523 4 13 4.44772 13 5V15.5858L18.2929 10.2929C18.6834 9.90237 19.3166 9.90237 19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071L12.7071 18.7071C12.3166 19.0976 11.6834 19.0976 11.2929 18.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L11 15.5858V5C11 4.44772 11.4477 4 12 4Z" fill="#8F999F"/>
    </svg>
  `;


  const svgReceivablesWhiteIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V3C23 1.89543 22.1046 1 21 1H3ZM12 4C12.5523 4 13 4.44772 13 5V15.5858L18.2929 10.2929C18.6834 9.90237 19.3166 9.90237 19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071L12.7071 18.7071C12.3166 19.0976 11.6834 19.0976 11.2929 18.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L11 15.5858V5C11 4.44772 11.4477 4 12 4Z" fill="white"/>
    </svg>
  `;

  const receivablesIcon = `data:image/svg+xml;base64,${btoa(svgReceivablesIcon)}`;
  const receivablesWhiteIcon = `data:image/svg+xml;base64,${btoa(svgReceivablesWhiteIcon)}`;

  const svgCustomersIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.248 2.16137C14.713 2.02439 14.1682 2.34706 14.0312 2.88208C13.8943 3.41711 14.2169 3.96189 14.752 4.09887C15.3973 4.2641 15.9692 4.6394 16.3777 5.16561C16.7861 5.69181 17.0078 6.339 17.0078 7.00512C17.0078 7.67125 16.7861 8.31843 16.3777 8.84464C15.9692 9.37085 15.3973 9.74615 14.752 9.91137C14.2169 10.0484 13.8943 10.5931 14.0312 11.1282C14.1682 11.6632 14.713 11.9859 15.248 11.8489C16.3236 11.5735 17.2768 10.948 17.9576 10.071C18.6383 9.19397 19.0078 8.11534 19.0078 7.00512C19.0078 5.89491 18.6383 4.81627 17.9576 3.93926C17.2768 3.06225 16.3236 2.43675 15.248 2.16137ZM13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM17 19V21H1V19C1 17.9391 1.42143 16.9217 2.17157 16.1716C2.92172 15.4214 3.93913 15 5 15H13C14.0609 15 15.0783 15.4214 15.8284 16.1716C16.5786 16.9217 17 17.9391 17 19ZM17.0318 13.8801C17.1698 13.3454 17.7153 13.0238 18.25 13.1619C19.3227 13.4388 20.273 14.0642 20.9517 14.9398C21.6304 15.8153 21.9992 16.8915 22 17.9994V20.0001C22 20.5524 21.5523 21.0001 21 21.0001C20.4477 21.0001 20 20.5524 20 20.0001V18.0009C19.9994 17.3363 19.7782 16.6904 19.371 16.1651C18.9638 15.6398 18.3936 15.2645 17.75 15.0984C17.2153 14.9603 16.8937 14.4149 17.0318 13.8801Z" fill="#8F999F"/>
    </svg>
  `;


  const svgCustomersWhiteIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.248 2.16137C14.713 2.02439 14.1682 2.34706 14.0312 2.88208C13.8943 3.41711 14.2169 3.96189 14.752 4.09887C15.3973 4.2641 15.9692 4.6394 16.3777 5.16561C16.7861 5.69181 17.0078 6.339 17.0078 7.00512C17.0078 7.67125 16.7861 8.31843 16.3777 8.84464C15.9692 9.37085 15.3973 9.74615 14.752 9.91137C14.2169 10.0484 13.8943 10.5931 14.0312 11.1282C14.1682 11.6632 14.713 11.9859 15.248 11.8489C16.3236 11.5735 17.2768 10.948 17.9576 10.071C18.6383 9.19397 19.0078 8.11534 19.0078 7.00512C19.0078 5.89491 18.6383 4.81627 17.9576 3.93926C17.2768 3.06225 16.3236 2.43675 15.248 2.16137ZM13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM17 19V21H1V19C1 17.9391 1.42143 16.9217 2.17157 16.1716C2.92172 15.4214 3.93913 15 5 15H13C14.0609 15 15.0783 15.4214 15.8284 16.1716C16.5786 16.9217 17 17.9391 17 19ZM17.0318 13.8801C17.1698 13.3454 17.7153 13.0238 18.25 13.1619C19.3227 13.4388 20.273 14.0642 20.9517 14.9398C21.6304 15.8153 21.9992 16.8915 22 17.9994V20.0001C22 20.5524 21.5523 21.0001 21 21.0001C20.4477 21.0001 20 20.5524 20 20.0001V18.0009C19.9994 17.3363 19.7782 16.6904 19.371 16.1651C18.9638 15.6398 18.3936 15.2645 17.75 15.0984C17.2153 14.9603 16.8937 14.4149 17.0318 13.8801Z" fill="white"/>
    </svg>
  `;

  const customersIcon = `data:image/svg+xml;base64,${btoa(svgCustomersIcon)}`;
  const customersWhiteIcon = `data:image/svg+xml;base64,${btoa(svgCustomersWhiteIcon)}`;

  const svgPayoutsIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3ZM15.9279 10.0904L16.3596 8.61774H14.5592C14.4826 8.2015 14.3464 7.81429 14.1506 7.45612C14.0644 7.29535 13.9655 7.14393 13.8539 7.00186L15.9279 7.01813L16.3596 5.54547H10.1389H9.88495H8.133L7.70135 6.96735H9.45331L10.4424 6.9751C11.2551 7.01787 11.8691 7.23745 12.2844 7.63385C12.5664 7.90047 12.7629 8.22843 12.8741 8.61774H8.133L7.70135 10.0904H12.9189C12.8637 10.343 12.7728 10.5673 12.6462 10.7632C12.4219 11.106 12.0982 11.3642 11.675 11.5377C11.2518 11.7069 10.7398 11.7916 10.1389 11.7916H7.82831L7.8537 12.858L12.5002 18.5455H14.4807V18.4439L10.1896 13.188H10.2277C11.2857 13.188 12.1363 13.0167 12.7795 12.6739C13.4269 12.3311 13.8967 11.8783 14.1887 11.3155C14.3893 10.9314 14.522 10.523 14.5867 10.0904H15.9279Z" fill="#8F999F"/>
    </svg>
  `;


  const svgPayoutsWhiteIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3ZM15.9279 10.0904L16.3596 8.61774H14.5592C14.4826 8.2015 14.3464 7.81429 14.1506 7.45612C14.0644 7.29535 13.9655 7.14393 13.8539 7.00186L15.9279 7.01813L16.3596 5.54547H10.1389H9.88495H8.133L7.70135 6.96735H9.45331L10.4424 6.9751C11.2551 7.01787 11.8691 7.23745 12.2844 7.63385C12.5664 7.90047 12.7629 8.22843 12.8741 8.61774H8.133L7.70135 10.0904H12.9189C12.8637 10.343 12.7728 10.5673 12.6462 10.7632C12.4219 11.106 12.0982 11.3642 11.675 11.5377C11.2518 11.7069 10.7398 11.7916 10.1389 11.7916H7.82831L7.8537 12.858L12.5002 18.5455H14.4807V18.4439L10.1896 13.188H10.2277C11.2857 13.188 12.1363 13.0167 12.7795 12.6739C13.4269 12.3311 13.8967 11.8783 14.1887 11.3155C14.3893 10.9314 14.522 10.523 14.5867 10.0904H15.9279Z" fill="white"/>
    </svg>
  `;

  const payoutsIcon = `data:image/svg+xml;base64,${btoa(svgPayoutsIcon)}`;
  const payoutsWhiteIcon = `data:image/svg+xml;base64,${btoa(svgPayoutsWhiteIcon)}`;

  const svgReportsIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V16.3794C4.5111 16.131 5.07857 16 5.65625 16H20V2ZM20 18H5.65625C5.19808 18 4.76997 18.1719 4.46336 18.4604C4.15881 18.7471 4 19.1224 4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V18Z" fill="#8F999F"/>
    </svg>
  `;


  const svgReportsWhiteIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V16.3794C4.5111 16.131 5.07857 16 5.65625 16H20V2ZM20 18H5.65625C5.19808 18 4.76997 18.1719 4.46336 18.4604C4.15881 18.7471 4 19.1224 4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V18Z" fill="white"/>
    </svg>
  `;

  const reportsIcon = `data:image/svg+xml;base64,${btoa(svgReportsIcon)}`;
  const reportsWhiteIcon = `data:image/svg+xml;base64,${btoa(svgReportsWhiteIcon)}`;

  const svgBankAccountsIcon = `
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.79289 2.79289C2.98043 2.60536 3.23478 2.5 3.5 2.5H12.5C12.7652 2.5 13.0196 2.60536 13.2071 2.79289C13.3946 2.98043 13.5 3.23478 13.5 3.5V4.5C13.5 5.05228 13.9477 5.5 14.5 5.5C15.0523 5.5 15.5 5.05228 15.5 4.5V3.5C15.5 2.70435 15.1839 1.94129 14.6213 1.37868C14.0587 0.81607 13.2956 0.5 12.5 0.5H3.5C2.70435 0.5 1.94129 0.81607 1.37868 1.37868C0.81607 1.94129 0.5 2.70435 0.5 3.5V12.5C0.5 13.2956 0.81607 14.0587 1.37868 14.6213C1.94129 15.1839 2.70435 15.5 3.5 15.5H4.5C5.05228 15.5 5.5 15.0523 5.5 14.5C5.5 13.9477 5.05228 13.5 4.5 13.5H3.5C3.23478 13.5 2.98043 13.3946 2.79289 13.2071C2.60536 13.0196 2.5 12.7652 2.5 12.5V3.5C2.5 3.23478 2.60536 2.98043 2.79289 2.79289ZM10.5 8.5H19.5C20.6046 8.5 21.5 9.39543 21.5 10.5V19.5C21.5 20.6046 20.6046 21.5 19.5 21.5H10.5C9.39543 21.5 8.5 20.6046 8.5 19.5V10.5C8.5 9.39543 9.39543 8.5 10.5 8.5Z" fill="#8F999F"/>
    </svg>
  `;


  const svgBankAccountWhiteIcon = `
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.79289 2.79289C2.98043 2.60536 3.23478 2.5 3.5 2.5H12.5C12.7652 2.5 13.0196 2.60536 13.2071 2.79289C13.3946 2.98043 13.5 3.23478 13.5 3.5V4.5C13.5 5.05228 13.9477 5.5 14.5 5.5C15.0523 5.5 15.5 5.05228 15.5 4.5V3.5C15.5 2.70435 15.1839 1.94129 14.6213 1.37868C14.0587 0.81607 13.2956 0.5 12.5 0.5H3.5C2.70435 0.5 1.94129 0.81607 1.37868 1.37868C0.81607 1.94129 0.5 2.70435 0.5 3.5V12.5C0.5 13.2956 0.81607 14.0587 1.37868 14.6213C1.94129 15.1839 2.70435 15.5 3.5 15.5H4.5C5.05228 15.5 5.5 15.0523 5.5 14.5C5.5 13.9477 5.05228 13.5 4.5 13.5H3.5C3.23478 13.5 2.98043 13.3946 2.79289 13.2071C2.60536 13.0196 2.5 12.7652 2.5 12.5V3.5C2.5 3.23478 2.60536 2.98043 2.79289 2.79289ZM10.5 8.5H19.5C20.6046 8.5 21.5 9.39543 21.5 10.5V19.5C21.5 20.6046 20.6046 21.5 19.5 21.5H10.5C9.39543 21.5 8.5 20.6046 8.5 19.5V10.5C8.5 9.39543 9.39543 8.5 10.5 8.5Z" fill="white"/>
    </svg>
  `;

  const bankAccountsIcon = `data:image/svg+xml;base64,${btoa(svgBankAccountsIcon)}`;
  const bankAccountWhiteIcon = `data:image/svg+xml;base64,${btoa(svgBankAccountWhiteIcon)}`;

  const svgProfileIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="user">
        <path id="Vector" d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" fill="#8F999F"/>
        <path id="Vector_2" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill="#8F999F"/>
      </g>
    </svg>
  `;


  const svgProfileActiveIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="user">
        <path id="Vector" d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" fill="white"/>
        <path id="Vector_2" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill="white"/>
      </g>
    </svg>
  `;

  const profileIcon = `data:image/svg+xml;base64,${btoa(svgProfileIcon)}`;
  const profileActiveIcon = `data:image/svg+xml;base64,${btoa(svgProfileActiveIcon)}`;

  const pointerIconSvg = `<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5547 9.29688C0.890145 9.73991 0 9.26352 0 8.46483L0 1.53519C0 0.736499 0.890145 0.260106 1.5547 0.703142L6.75192 4.16796C7.34566 4.56378 7.34566 5.43624 6.75192 5.83206L1.5547 9.29688Z" fill="#181C30"/>
                              </svg>`

  const pointerIcon = `data:image/svg+xml;base64,${btoa(pointerIconSvg)}`;

  const sideIconSvg = `<svg width="21" height="68" viewBox="0 0 21 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                <path d="M1 0V14C1 17.7712 1 19.6569 2.17157 20.8284C3.34315 22 5.22876 22 9 22H21" stroke="#181C30"/>
                                <path d="M1 14V59C1 62.7712 1 64.6569 2.17157 65.8284C3.34315 67 5.22876 67 9 67H21" stroke="#181C30"/>
                                </g>
                              </svg>`

  const sideIcon = `data:image/svg+xml;base64,${btoa(sideIconSvg)}`;

  if (storedUserRole === 'malik') {
    AuthBankSideBarList.push(
      {
        id: 8,
        name: 'Bank Accounts',
        isActive: true,
        address: '/bank-accounts',
        icon: bankAccountsIcon,
        activeIcon: bankAccountWhiteIcon,
      },
    );

    AuthProfileSideBarList.push(
      {
        id: 12,
        name: 'Profile & Settings',
        isActive: true,
        address: '/profile-settings',
        icon: profileIcon,
        activeIcon: profileActiveIcon,
      },
    );
  }

  const SideBarList = [
    {
      id: 1,
      name: 'Dashboard',
      isActive: true,
      address: '/dashboard',
      icon: dashboardIcon,
      activeIcon: dashboardWhiteIcon,
      role : "user"
    },
    {
      id: 2,
      name: 'Receivables',
      isActive: true,
      address: '/receivables',
      address2: '/receivablesDraft',
      icon: receivablesIcon,
      activeIcon: receivablesWhiteIcon,
      role : "user"
    },
    {
      id: 3,
      name: 'Customers',
      isActive: true,
      address: '/customers',
      icon: customersIcon,
      activeIcon: customersWhiteIcon,
      role : "user"
    },
    // {
    //   id: 4,
    //   name: "Cashflow",
    //   isActive: false,
    //   address: "/cashflow",
    //   icon: cashflowIcon,
    //   activeIcon: cashflowWhiteIcon,
    // },
    // {
    //   id: 5,
    //   name: "Financing",
    //   isActive: false,
    //   address: "/financing",
    //   icon: loanIcon,
    //   activeIcon: financingWhiteIcon,
    // },
    // {
    //   id: 6,
    //   name: "Payouts",
    //   isActive: false,
    //   address: "/payouts",
    //   icon: payoutsIcon,
    //   activeIcon: payoutsWhiteIcon,
    // },
    // {
    //   id: 7,
    //   name: "Vendors",
    //   isActive: false,
    //   address: "/vendors",
    //   icon: vendorsIcon,
    //   activeIcon: vendorsWhiteIcon,
    // },
    // {
    //   id: 8,
    //   name: 'Bank Accounts',
    //   isActive: true,
    //   address: '/bank-accounts',
    //   icon: bankAccountsIcon,
    //   activeIcon: bankAccountWhiteIcon,
    // },
    {
      id: 6,
      name: 'Payouts',
      isActive: true,
      address: '/payouts',
      icon: payoutsIcon,
      activeIcon: payoutsWhiteIcon,
      role : "user"
    },
    ...AuthBankSideBarList,
    {
      id: 9,
      name: 'FX Rates',
      isActive: true,
      address: '/fx-rates',
      icon: fxRatesIcon,
      activeIcon: fxRatesActiveIcon,
      role : "user"
    },
    {
      id: 10,
      name: 'Analytics',
      isActive: true,
      address: '/reports',
      icon: reportsIcon,
      activeIcon: reportsWhiteIcon,
      role : "user"
    },
    {
      id: 11,
      name: "Compliance",
      isActive: true,
      address: "/compliance",
      icon: complianceIcon,
      activeIcon: complianceWhiteIcon,
      role: "user"
    },
    // {
    //   id: 11,
    //   name: 'Settings',
    //   isActive: true,
    //   address: '/settings',
    //   icon: settingsIcon,
    //   activeIcon: settingsWhiteIcon,
    // },

    // {
    //   id: 12,
    //   name: 'Profile & Settings',
    //   isActive: true,
    //   address: '/profile-settings',
    //   icon: profileIcon,
    //   activeIcon: profileActiveIcon,
    // },
    ...AuthProfileSideBarList
  ]

  // console.log('isReceivablesOpen',isReceivablesOpen)

  return (
    <>
      {
      tourStart &&
        ReactDOM.createPortal(
          <TourStartPrompt
            firstName = {userName ? userName:"User"}
            // lastName = {'Sahni'}
          />,
          document.getElementById('modal-root')
        )
      }
      {showSideBarTablet &&
        ReactDOM.createPortal(
          <Backdrop
            onClick={() => dispatch(GlobalActions.setShowSideBarTablet(false))}
            className={classes.backdrop1}
          />,
          document.getElementById("backdrop-root")
        )}
      {showSideBarMobile &&
        ReactDOM.createPortal(
          <Backdrop
            onClick={() => dispatch(GlobalActions.setShowSideBarMobile(false))}
            className={classes.backdrop2}
          />,
          document.getElementById("backdrop-root")
        )}
        
      <div
          className={`${classes.sidebar} ${
            showSideBarTablet && classes.sidebar_open
          } ${props.className}`}
        >
          {showSideBarMobile && (
          <img
            src={fideoLogo}
            alt="fideo-logo"
            className={"h-[24px] pl-24 mb-16 z-50 sm:hidden"}
          />
        )}
        <ul>
          {SideBarList.map((opt) => (
            (<li
              key={opt.id}
              className={clsx(
                classes.list,
                showSideBarTablet && classes.list_open,
                !opt.isActive && "opacity-25"
              )}
              onClick={navLinkClickHandler}
            >
              <NavLink
                onClick={(e) => {
                  !opt?.isActive && e.preventDefault();
                  // setSelectedOption(opt.id);
                  dispatch(GlobalActions.setSelectedOption(opt.id));
                  opt.name !== 'Receivables' && subMenu !== "all" && dispatch(GlobalActions.setSubMenu("all"));
                }}
                to={opt.name === 'Receivables' ? (subMenu === "all" ? opt.address : opt.address2) : opt.address }
                className={({ isActive }) =>
                  optionHandler({ isActive, id: opt.id, navName : opt.name })
                }
                end
              >
                <div
                  className={`${classes.icon_box} ${
                    showSideBarTablet && classes.icon_box_open
                  }`}
                >
                  <img
                    src={`${
                      selectedOption === opt.id ? opt.activeIcon : opt.icon
                    }`}
                    alt={`${opt.address}-icon`}
                    className={`${classes.icon}`}
                  />
                </div>
                <div
                  className={clsx(
                    classes.name,
                    showSideBarTablet && classes.name_open,
                      "flex w-[90%] justify-between"
                  )}
                >
                  {opt.name}

                  {/*{opt.name === 'Receivables' && <img src={isReceivablesOpen ? chevronUp : chevronDown} style={{marginRight:"5px"}}/>}*/}
                </div>
              </NavLink>
              {/*{opt.name === 'Receivables' && isReceivablesOpen &&*/}
              { opt.name === 'Receivables' &&
                <div className={classes.draftCon}>
                    <div className={classes.draftLeft}>
                      <img src={sideIcon} style={{height:"50px"}}/>
                    </div>
                    <div className={classes.draftRight}>
                      <NavLink
                          to={opt.address}
                          // className={({ isActive }) =>
                          //     optionHandler({ isActive, id: opt.id })
                          // }
                      >
                      <div className={classes.allCon}
                           // style={{marginTop:"-5px"}}
                           onClick={()=>handleSubMenu("all",true,opt.id)}
                      >
                        <p className={classes.allText} style={{color : subMenu === "all" && opt?.id === selectedOption ? "#181C30" : "#181C3066"}}>All</p>
                        {subMenu === "all" && opt?.id === selectedOption && <img src={pointerIcon} />}
                      </div>
                      </NavLink>

                      <NavLink
                          to={`${opt.address2}`}
                          // className={({ isActive }) =>
                          //     optionHandler({ isActive, id: opt.id })
                          // }
                      >
                      <div className={clsx(classes.allCon)}
                           // style={{marginTop:"-5px"}}
                           onClick={()=>handleSubMenu("draft",true,opt.id)}
                      >
                        <p className={classes.allText} style={{color : subMenu === "draft" && opt?.id === selectedOption ? "#181C30" : "#181C3066"}}>Draft</p>
                        {subMenu === "draft" && opt?.id === selectedOption && <img src={pointerIcon}/>}
                      </div>
                      </NavLink>
                    </div>
                </div>
              }
            </li>)
          ))}


        </ul>
          <div className={classes.tourHeaderWrap}>
           <div className={classes.tourHeader} onClick={()=>dispatch(modalActions.tourStart(true))}>
             <img src={airplay} alt="" className={classes.tourStartImg}/>
             <p className={classes.tourStartHead}>Start Tour</p>
             <img src={chevronRight} alt="" className={classes.tourStartImg2}/>
           </div>
          </div>
        </div>
       
    </>
  );
};
export default SideBar;
