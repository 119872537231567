import PageIntro from "Components/Global/PageIntro";
import classes from "Pages/Admin/Dashboard.module.scss";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import TransactionDetails from "Components/Global/TransactionDetails";
import arrowDown from "assets/Images/arrow_down.svg";
import PlusIcon from "assets/Icons/plus-black.svg";
import UploadInvoicesIcon from "assets/Icons/upload-invoices.svg";
import editInvoicesIcon from "assets/Icons/editBlack.svg";

import ArrowUp from "assets/Icons/arrow-up-traingle-white.svg";
import ReceivePayment from "Components/Payment/ReceivePayment";
import rightArrow from "assets/Images/arrow-right.svg";
import blackArrowDown from "assets/Images/arrow-down-black.svg";
import arrowDownBlack from "assets/Images/arrow-down-black.svg";
import { useEffect, useState } from "react";
import userAddBlack from "assets/Images/user-add.svg";
import userAddWhite from "assets/Images/user-add-white.svg";
// import book from "assets/Images/book.svg";
// import Button from "Layout/Button";
import useClickOutside from "hooks/useClickOutside";
import { Link } from "react-router-dom";
import leftArrow from "assets/Icons/arrow-left.svg";
import viewBoxCountry from "assets/Icons/viewBoxCountry.svg";
import { DashboardSummaryActions } from "Store/customer-redux";
import {
  apiGetCustomerCurrencies,
  apiGetDashboardInvoice,
  apiGetNewSummary,
  apiGetSummary,
  apiGetVirtualBankDetailsWithParams,
  apiProfile
} from "services";
import UseApiCall from "hooks/useApiCall";
import clsx from "clsx";
import { countryCurrency } from "constants/countryCurrency";
import CustomModal from "Components/atom/CustomModal/CustomModal";
import ComingSoonPrompt from "Components/atom/ComingSoonPrompt/ComingSoonPrompt";
import AddContact from "Components/General/AddContact";
import UnderVericationPrompt from "Components/atom/UnderVericationPrompt/UnderVericationPrompt";
import PaymentDetails from "Components/Payment/PaymentDetails";
import PaymentStatusTag from "Components/ReportTable/PaymentStatusTag";
import DraftInvoiceBanner from 'Components/atom/DraftInvoiceBanner/DraftInvoiceBanner'
import SendPaymentLinkPrompt from "Components/atom/SendPaymentLinkPrompt/SendPaymentLinkPrompt";
import { REFRESH_TOKEN } from "constants";
import ActionWithOutIcon from 'Components/ReportTable/ActionWithOutIcon'
import ReceiveBulkUploadPayment from 'Components/Payment/ReceiveBulkUploadPayment'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import ReconcileBankDetails from "Components/Global/ReconcileBankDetails";
import { BankAccountActions } from "../../Store/bank-redux";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import Shimmer from "Layout/Shimmer";
import CreateInvoice from "../../Components/CreateInvoice/CreateInvoice";
import DraftEditInvoice from "../../Components/CreateInvoice/DraftEditInvoice";

let tempCurrencySet = new Set();
let tempCurrencyArr = [];
let dropdownTemp = [];

for (let i = 0; i < countryCurrency.length; i++) {
  const currency = countryCurrency[i].currency_code;

  if (!tempCurrencySet.has(currency) && currency !== null) {
    tempCurrencySet.add(currency);
    tempCurrencyArr.push(currency);
  }
}

for (let currency of tempCurrencyArr.sort()) {
  dropdownTemp.push({
    id: currency,
    heading: currency,
  });
}

const dummyResponse = {
  data: {
    summary: [
      {
        status: "CREATED",
        count: 3,
        amount: 2300,
        currency: "$",
      },
      {
        status: "SETTLED",
        count: 1,
        amount: 300.0,
        currency: "$",
      },
      {
        status: "OVERDUE",
        count: 1,
        amount: 0,
        currency: "$",
      },
      {
        status: "OUTSTANDING",
        count: 1,
        amount: 2000,
        currency: "$",
      },
    ],
  },
  error_response: null,
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const optRef = useClickOutside(()=>{
    dispatch(modalActions.invoiceActionDisplay(false))
  })
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFirstCurrencylist, setShowFirstCurrencyList] = useState(false);
  const [showSecondCurrencylist, setShowSecondCurrencyList] = useState(false);
  const [forexFirstCurrency, setForexFirstCurrency] = useState({
    id: "USD",
    heading: "USD",
  });
  const [forexSecondCurrency, setForexSecondCurrency] = useState({
    id: "INR",
    heading: "INR",
  });
  const firstCurrencyDropdownRef = useClickOutside(() =>
    setShowFirstCurrencyList(false)
  );
  const secondCurrencyDropdownRef = useClickOutside(() =>
    setShowSecondCurrencyList(false)
  );
  const receivePaymentDisplay = useSelector(
    (state) => state.modal.receivePaymentDisplay
  );
  const invoiceActionDisplay = useSelector((state) => state.modal.invoiceActionDisplay);
  const bulkUploaderDisplay = useSelector((state) => state.modal.bulkUploaderDisplay);
  const createInvoiceModal = useSelector((state) => state.modal.createInvoiceModal);
  const showDraftEditInvoice = useSelector((state) => state.modal.showDraftEditInvoice);
  const createInvoiceReceivePayment = useSelector((state) => state.modal.createInvoiceReceivePayment);
  const addContactDisplay = useSelector(
    (state) => state.modal.addContactDisplay
  );
  const updateDashboardValue = useSelector((state) => state.dashboardSummary.updateDashboard);
  const virtualBankDetails = useSelector((state) => state.bankAccount.virtualAccounts);
  const [transactionList, setTransactionList] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState();
  const targetValues = ["INVOICE_CANCELLED", "INVOICE_SETTLED_OUTSIDE_FIDEO"];
  const [smallestIndex, setSmallestIndex] = useState(10);
  const summary = useSelector((state) => state.dashboardSummary.summary);
  const [modalRef, setModalRef] = useState(null);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balances, setBalances] = useState(null);
  const [key, setKey] = useState(false)
  const showBackendError = useSelector((state) => state.dashboardSummary.showBackendError);
  const [customerCurrencies, setCustomerCurrencies] = useState({})



  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  // const [getSummary, fetchingSummary, data, error] = UseApiCall(apiGetSummary, (res) => {
  //   dispatch(DashboardSummaryActions.setSummary(res?.data?.summary));
  // }, (err) => {
  //   dispatch(DashboardSummaryActions.setShowBackendError(err));
  // });

  const [getNewSummary, fetchingNewSummary, Newdata, Newerror] = UseApiCall(apiGetNewSummary,
      (res) => {
              // dispatch(DashboardSummaryActions.setSummary(res?.data?.summary));
        console.log('resnewSummarry',res)
        },
      (err) => {
              // dispatch(DashboardSummaryActions.setShowBackendError(err));
        }
  );

  const [getCustomerCurrencies] = UseApiCall(
      apiGetCustomerCurrencies,
      (res) => {
        setCustomerCurrencies(res?.data)
      }
  )

  const [getVirtualBankDetails] = UseApiCall(
    apiGetVirtualBankDetailsWithParams,
    (res) => {
      setBalances(res?.data?.balances);
      if (res?.data?.balances?.USD?.balance) setBalanceUSD(res?.data?.balances?.USD?.balance);
      dispatch(BankAccountActions.setVirtualAccounts(res?.data));
    }
  );
  
  const [getInvoices, fetchingInvoices] = UseApiCall(
    apiGetDashboardInvoice,
    (res) => {
      setTransactionList(res?.data?.invoice_list);
      if (window.innerWidth > 1210 && res?.data?.invoice_list?.length > 0)
        setActiveTransaction(res?.data?.invoice_list[0]);
        let indexValue = true;
        res?.data?.invoice_list[0]?.transactions_detail?.transaction_info_list?.forEach((item, index) => {
          if (indexValue && targetValues.includes(item?.transaction_action) && item?.is_processed) {
            indexValue = false;
            // setSmallestIndex(() =>(index));
          }
        });
    
        if (indexValue) {
          // setSmallestIndex(res?.data?.invoice_list[0]?.transactions_detail?.transaction_info_list?.length);
        }
    }
  );

  const showComingSoon = () => {
    modalRef.show({
      title: "Coming Soon",
      child: <ComingSoonPrompt />,
      onClose: () => {},
      compactSize: true,
      closeOnClickOutside: true,
    });
  };

  const [quickLink, setQuickLink] = useState([
    {
      id: 1,
      name: "Receive Payment",
      icon: arrowDownBlack,
      iconHover: arrowDownBlack,
      alt: "arrow-up-black-icon",
      active: true,
      isHovered: false,
      func: function () {
        dispatch(modalActions.receivePayment());
        // showComingSoon();
      },
    },
    {
      id: 2,
      name: "Add Customer",
      icon: userAddBlack,
      iconHover: userAddWhite,
      alt: "user-add-icon",
      active: true,
      isHovered: false,
      func: function () {
        dispatch(modalActions.addContact());
        // showComingSoon();
      },
    },
    // {
    //     id: 3,
    //     name: 'Add Vendor',
    //     icon: briefcaseBlack,
    //     alt: 'briefcase-icon',
    //     func: function () {
    //     },
    // },
    // {
    //   id: 4,
    //   name: "Generate FIRA",
    //   icon: book,
    //   alt: "book-icon",
    //   active: false,
    //   func: function () {
    //     showComingSoon();
    //   },
    // },
  ]);

  const arrBtn = [
    {
      text: "Add Customer",
      icon: userAddWhite,
      active: true,
      onClick: function () {
        dispatch(modalActions.addContact());
      },
    },
    // {
    //   text: "Receive Payment",
    //   icon: arrowDown,
    //   active: true,
    //   onClick: function () {
    //     dispatch(modalActions.receivePayment());
    //     // showComingSoon();
    //   },
    // },
  ];

  const optionArr = [
    {
      text: 'Add Single Invoice',
      icon: PlusIcon,
      onClick: function () {
        dispatch(modalActions.receivePayment());
      },
    },
    {
      text: 'Upload Multiple Invoices',
      icon: UploadInvoicesIcon,
      // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
      onClick: function () {
        dispatch(modalActions.bulkUploaderDisplay(true))
      },
    },
    {
      text: 'Create Invoice',
      icon: editInvoicesIcon,
      // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
      onClick: function () {
        // dispatch(modalActions.setCreateInvoiceModal(true))
        dispatch(modalActions.setCreateInvoiceReceivePayment(true))
      },
    },
  ]

  const showUnderVerification = (name = "") => {
    modalRef.show({
      title: "What’s next?",
      child: (
        <UnderVericationPrompt
          name={name}
          closeModal={() => {
            modalRef?.close();
          }}
        />
      ),
      onClose: () => {},
      compactSize: true,
      closeOnClickOutside: true,
    });
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    setActiveTransaction();
    // getSummary();
    getNewSummary();
    getInvoices({ page: 0 });
    if (storedUserRole === 'malik') getVirtualBankDetails(`?fetchBalanceOnly=true`);
  }, [updateDashboardValue]);

  // useEffect(() => {
  //   dispatch(() => {
  //     dispatch(modalActions.setCreateInvoiceModalRec(false))
  //     dispatch(modalActions.setCreateInvoiceReceivePaymentRec(false))
  //     dispatch(modalActions.setCreateInvoiceModalRecDraft(false))
  //     dispatch(modalActions.setCreateInvoiceReceivePaymentRecDraft(false))
  //   })
  // }, []);

  useEffect(() => {
    dispatch(() => {
      dispatch(modalActions.setCreateInvoiceModal(false))
      dispatch(modalActions.setShowDraftEditInvoice(false))
      dispatch(modalActions.setCreateInvoiceReceivePayment(false))
    })
  }, []);

  console.log(process.env, "Paaaaaaaaaaaabo")

  if(createInvoiceModal){
    return (
        <>
          <CreateInvoice/>
        </>
    )
  }

  if(showDraftEditInvoice){
    return (
        <>
          <DraftEditInvoice
              selectedInvoiceId={activeTransaction?.id}
          />
        </>
    )
  }

  return (
    <div className={classes.dashboard}>
      <CustomModal ref={setModalRef} />

      {(createInvoiceReceivePayment) &&
          ReactDOM.createPortal(
              <ReceivePayment modal={"createInvoice"}/>,
              document.getElementById("modal-root")
      )}

      {(receivePaymentDisplay) &&
        ReactDOM.createPortal(
          <ReceivePayment />,
          document.getElementById("modal-root")
      )}

      {invoiceActionDisplay  && screenWidth<640 &&
        ReactDOM.createPortal(
          <ActionWithOutIcon
            option={[...optionArr]}
            optRef = {optRef}
            // cellData={"data"}
          />,
          document.getElementById("modal-root")
        )}
      {bulkUploaderDisplay &&
        ReactDOM.createPortal(
          <ReceiveBulkUploadPayment />,
          document.getElementById('modal-root')
      )}
      {addContactDisplay &&
        ReactDOM.createPortal(
          <AddContact
            contactType={"Customer"}
            nextPageFunctionality={() => {
              dispatch(modalActions.addContact());
            }}
            onSubmit={(name) => {
              showUnderVerification(name);
            }}
            addContactURL={""}
          />,
          document.getElementById("modal-root")
        )}
      <div>
        {
          balances && storedUserRole === 'malik' &&
            Object.entries(balances)
              .filter(([currency, { balance }]) => balance !== null && balance > 0)
              .length > 0 && (
                <ReconcileBankDetails allBalances={balances} />
              )
        }
      </div>
      <PageIntro
        pageName={"Dashboard"}
        createInvoice={'dashboard'}
        message={window.innerWidth > 500 ? "Track and Manage cross-border transactions with ease" : "Manage cross-border transactions with ease"}
        arrBtn={arrBtn}
        dontShowBtn={true}
        optRef = {optRef}
      />

      {/* {!fetchingSummary
        ?
          summary && summary?.length > 0 && (
            <TransactionDetails transactionData={summary} />
          )
        :
          <>
            <Shimmer className="w-[100%] mb-[10px] h-[40px] rounded-lg"/>
            <Shimmer className="w-[100%] mb-[40px] h-[240px] rounded-lg"/>
          </>
      } */}
      <TransactionDetails transactionData={Newdata?.data} loading={fetchingNewSummary} />
      {/* <div className={classes.viewDetailContainer}>
        <div className={classes.viewDetailBoxOne}>
          <div className={classes.viewDetailBoxOneImg}>
            <img src={viewBoxCountry} alt="country-group"  />
          </div>
          <div className={classes.viewDetailBoxOneText}>
            <span className={classes.viewDetailBoxOneHeading}>Your Global Virtual Bank Account Details</span>
            <span className={classes.viewDetailBoxOneDesc}>Bank account details for USD, EUR, GBP & CAD currencies against local method & swift are ready. Copy/ Download & share it with your customers.</span>
          </div>
        </div>
        <div className={classes.viewDetailBoxTwo}>
            <Link to="/bank-accounts">
              <span className={classes.viewDetailBoxTwoText}>View Details</span>
            </Link>
        </div>
      </div> */}
      <div className={classes.recent_transaction}>
        <div className={classes.header}>
          <div className={classes.quick_link_header}>Recent transactions</div>
          <Link to="/receivables" className={classes.link}>
            <div className={classes.all}>See all</div>
            <img
              src={rightArrow}
              alt="right-arrow-icon"
              className={classes.rightArrowIcon}
            />
          </Link>
        </div>
        {transactionList?.length <= 0 && 
          <div className={classes.activity}>
            <span className={classes.viewDetailBoxOneDesc}>No Recent transactions Yet.</span>
          </div>
        }
        <div className={classes.activity}>
          <ul className={classes.all_list}>
            {transactionList?.slice(0, 5).map((trans) => (
              <li
                key={trans.invoice_id}
                className={`${classes.list} ${
                  activeTransaction !== undefined &&
                  activeTransaction.invoice_id === trans.invoice_id
                    ? classes.list_active
                    : undefined
                } items-center`}
                onClick={() => {
                  let indexValue = true;
                  trans?.transactions_detail?.transaction_info_list?.forEach((item, index) => {
                    if (indexValue && targetValues.includes(item?.transaction_action) && item?.is_processed) {
                      indexValue = false;
                      // setSmallestIndex(() => (index));
                    }
                  });
              
                  if (indexValue) {
                    // setSmallestIndex(trans?.transactions_detail?.transaction_info_list?.length);
                  }
                  setActiveTransaction(trans)
                }}
              >
                <img
                  src={blackArrowDown}
                  alt="black-arrow-down"
                  className={`${classes.black_down_arrow} ${
                    activeTransaction !== undefined &&
                    activeTransaction.invoice_id === trans.invoice_id
                      ? classes.black_down_arrow_active
                      : undefined
                  }`}
                />
                <div className={classes.name_id} style={{ width: "100%", padding: "2% 0" }}>
                  <div className={classes.name} style={{ overflowWrap: "anywhere", width: "90%" }}>{trans.customer_name}</div>
                  <div className={classes.invoiceId} style={{ overflowWrap: "anywhere", width: "90%" }}>
                    Invoice ID: {trans.invoice_id}
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.amount} style={{ marginRight: "8px" }}>
                    {trans?.currency?.sign}
                    {formatNumberToLocaleString((trans?.receiving_amount), 2, 2)}
                  </div>
                  <PaymentStatusTag status={trans.status} type="light" />
                </div>
              </li>
            ))}
          </ul>
          {activeTransaction !== undefined && (
            <div className={classes.payment_status_all} style={{ overscrollBehavior: "contain" }}>
              <div className={classes.payment_status_all_header}>
                <img
                  src={leftArrow}
                  alt="left-arrow-icon"
                  className={classes.payment_status_all_header_icon}
                  onClick={() => setActiveTransaction(undefined)}
                />
                <div className={classes.payment_status_all_header_heading}>
                  Payment Status
                </div>
              </div>
              <li
                className={`${classes.list} ${classes.list_active} ${classes.activeCustomer}`}
              >
                <img
                  src={blackArrowDown}
                  alt="black-arrow-down"
                  className={`${classes.black_down_arrow} ${classes.black_down_arrow_active}`}
                />
                <div className={classes.name_id}>
                  <div className={classes.name}>
                    {activeTransaction.customer_name}
                  </div>
                  <div className={classes.invoiceId}>
                    Invoice ID: {activeTransaction.invoice_id}
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.amount}>
                    {activeTransaction.currency?.sign}
                    {activeTransaction.amount}
                  </div>
                  <PaymentStatusTag
                    status={activeTransaction.status}
                    className={classes.status}
                    withIcon={true}
                  />
                </div>
              </li>
              <PaymentDetails
                className={classes.payment_status}
                transactionData={activeTransaction}
                smallestIndex={smallestIndex}
                invoice_id={activeTransaction?.invoice_id}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.viewDetailContainer}>
        <div className={classes.viewDetailBoxOne}>
          <div className={classes.viewDetailBoxOneImg}>
            <img src={viewBoxCountry} alt="country-group"  />
          </div>
          <div className={classes.viewDetailBoxOneText}>
            <span className={classes.viewDetailBoxOneHeading}>Your Global Virtual Bank Account Details</span>
            <span className={classes.viewDetailBoxOneDesc}>Virtual bank account details for USD, EUR, GBP & CAD currencies against local method & swift are ready. Copy/ Download & share it with your customers.</span>
          </div>
        </div>
        <div className={classes.viewDetailBoxTwo}>
            <Link to="/bank-accounts">
              <span className={classes.viewDetailBoxTwoText}>View Details</span>
            </Link>
        </div>
      </div>
      <div className={classes.quick_link}>
        <div className={classes.quick_link_header}>Quick Links</div>
        <div className={classes.quick_link_all}>
          {quickLink.map((link, index) => (
            <div
              key={link.id}
              className={clsx(
                classes.quick_link_all_box,
                "max-w-[25rem]",
                link.active ? "opacity-100 cursor-pointer" : 'opacity-50',
                (screenWidth < 600 && activeTransaction !== undefined) ? 'opacity-0' : '',
                // 'hover:bg-[#181C30] hover:text-[#FFF]'
              )}
              onClick={() => {
                if (link.active) link.func();
              }}
              onMouseEnter={() => {
                setQuickLink((prevLinks) => {
                  const updatedLinks = [...prevLinks];
                  updatedLinks[index].isHovered = true;
                  return updatedLinks;
                })
              }}
              onMouseLeave={() => {
                setQuickLink((prevLinks) => {
                  const updatedLinks = [...prevLinks];
                  updatedLinks[index].isHovered = false;
                  return updatedLinks;
                });
              }}
            >
              <div
                className={clsx(
                  classes.quick_link_all_box_circle,
                  link.isHovered && "bg-[#181C30]"
                )}
              >
                <img
                  src={link.isHovered ? link.iconHover : link.icon}
                  alt={link.alt}
                  className={classes.quick_link_all_box_circle_icon}
                />
              </div>
              <div className={classes.quick_link_all_box_name}>{link.name}</div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className={classes.forex_all}>
                <div className={classes.forex_all_header}>Fideo conversion rates</div>
                <div className={classes.forex}>
                    <div className={classes.forex_converter}>
                        {forexConverter.map(converter =>
                            <div key={converter.title}>
                                <div className={classes.forex_converter_header}>{converter.title}</div>
                                <div className={classes.forex_converter_text}>
                                    <input className={classes.forex_converter_text_input} type='number' placeholder='amount' value={converter.value}/>
                                    <div ref={converter.ref} className={classes.currency_list}>
                                        <div className={classes.forexCurrency}>{converter.currency.id}</div>
                                        <img src={chevronDown} alt='chevron-down-icon' className={`${classes.dropdown_icon} ${converter.showCurrencyList && classes.dropdown_icon_active}`} onClick={converter.setShowDropdown}/>
                                        {converter.showCurrencyList &&
                                            <Dropdown
                                                className={classes.currency_dropdown}
                                                list={converter.dropdown}
                                                onClick={converter.onClick}
                                                selectedOption = {converter.currency}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={classes.message}>Price as per Google: 84.22</div>
                    </div>
                    <div className={classes.forex_rates}>
                        {conversionRateList.map(list =>
                            <div className={classes.forex_rates_convertion}>
                                <img src={list.fromCountryFlag} alt='flag' className={classes.flag}/>
                                <div className={classes.currency}>{list.from}</div>
                                <img src={leftRightArrow} alt='left-right-arrow' className={classes.leftRightArrow}/>
                                <div className={classes.currency}>{list.to}</div>
                                <img src={list.toCountryFlag} alt='flag' className={classes.flag}/>
                                <div className={classes.amount}>{list.amount}</div>
                            </div>
                        )}
                        <div className={classes.message}>Last updated: 2 hours ago</div>
                    </div>
                </div>
            </div> */}
      <div className={classes.button}>
        {/* {arrBtn.map((item, index) => (
          <Button
            key={item.text}
            text={item.text}
            icon={item.icon}
            onClick={item.onClick}
            className={classes.btn}
            disabled={!item.active}
            btnClassIcon={clsx(!item.active && "opacity-50")}
            btnClassText={clsx(!item.active && "opacity-50")}
          />
        ))} */}

        <button
            onClick={() => dispatch(modalActions.invoiceActionDisplay(true))}
            className='flex items-stretch w-[100%]'
            >
              <div></div>
              <div className={classes.btnLeftPart}>
                <img
                    src={arrowDown}
                    alt={`ArrowLeft-icon`}
                    className=''
                />
                <div className={classes.btnFontText} style={{ color: true ? "gray" : "white"}}>Receive Payment</div>
                </div>
              <div className={classes.btnRightPart} style={{ height: "100%" }}>
                <img
                    src={ArrowUp}
                    alt={`ArrowLeft-icon`}
                    className=''
                />
              </div>
        </button>
      </div>
    </div>
  );
};
export default Dashboard;

// export const fetchSummary = async () => {

//     return await fetch(BASE_URL + "/invoices/summary").then((res) => {
//         if (!res.ok) {
//             return dummyResponse
//         }
//         return res.json()
//     })

// }
